<template>
  <div v-if="testCompleted">
    <!-- 包裹按钮的容器，应用样式使按钮居左 -->
    <div class="restart-test">
      <el-button class="button-background-color" @click="startTest">{{ $t('ProductDiagnosis.restartTest') }}</el-button>
    </div>
    <el-table :header-cell-style="{background:'rgb(250, 250, 250)'}" :data="tableData" style="width: 100%"
              class="table-height">
      <el-table-column :label="$t('ProductDiagnosis.sequenceNumber')" type="index" :min-width="100"></el-table-column>

      <el-table-column :label="$t('ProductDiagnosis.module') " prop="module"></el-table-column>
      <el-table-column :label="$t('ProductDiagnosis.testData')" prop="testData">
        <template v-slot="{ row }">
          <!-- 检查row.testData是否有值并且不是'-'，是则显示值和单位，否则只显示'-' -->
          {{ row.testData && row.testData !== '-' ? `${row.testData} ${unitMapping[row.testContent] || ''}` : '-' }}
        </template>
      </el-table-column>

      <el-table-column :label="$t('ProductDiagnosis.testContent')" prop="testContent"
      ></el-table-column>

      <el-table-column :label="$t('ProductDiagnosis.testResult')">
        <template #default="scope">

          <el-tag :type="scope.row.isErrorName === 1 ? 'danger' : scope.row.isErrorName === 2 ? 'warning' : 'success'">
            {{ scope.row.isErrorName === 1 ? $t('ProductDiagnosis.error') : scope.row.isErrorName === 2 ?  $t('ProductDiagnosis.untested') : $t('ProductDiagnosis.normal') }}
          </el-tag>

        </template>
      </el-table-column>

      <el-table-column :label="$t('ProductDiagnosis.helpManual')" prop="manual">
        <template #default="scope">
          <span v-if="scope.row.isErrorName !== 1">-</span>
          <template v-else>
            <a v-if="scope.row.manual_link !== '-'" :href="scope.row.manual_link" target="_blank"
               style="text-decoration: none;color: rgb(0,154,68)">
              {{ scope.row.manual }}
            </a>
            <span v-else>
              {{ scope.row.manual }}
            </span>
          </template>
        </template>
      </el-table-column>

      <template v-slot:empty>
        <EmptySlot/>
      </template>

    </el-table>

  </div>

  <div v-if="!testCompleted " class="dynamic-test-content">
    <div class="device-connection">

      <div v-if="showNoDeviceConnected">
        <img class="device-image" :src="noDeviceConnectImgUrl" width="399"
             height="263.19"
             alt="Device Image"/>
        <p class="no-device-text"> {{ $t('ProductDiagnosis.noDeviceConnected') }}</p>
      </div>

      <div
          v-if="!showNoDeviceConnected && (isMotorConnected || isDisplayConnected) && !testStarted">
        <p style="font-size: 20px;">{{ connectedDevicesDescription }}</p>
        <p style="font-size: 16px;margin-bottom: 40px;">{{ $t('ProductDiagnosis.ensureBatteryOn') }}</p>
        <el-button :class="{ 'custom-button': isStartTestDisabled, 'button-active': !isStartTestDisabled}"
                   @click="startTest" :disabled="isStartTestDisabled"> {{ $t('ProductDiagnosis.startTest') }}
        </el-button>
      </div>

      <div v-if="(isMotorConnected || isDisplayConnected) &&testStarted" class="progress-bar-container">

        <el-steps v-if="activeStep < 2" :active="activeStep" finish-status="success" align-center
                  style="margin-bottom: 20px;">
          <el-step :title="$t('ProductDiagnosis.determinePeripheral')"></el-step>
          <el-step :title="$t('ProductDiagnosis.determineTestItems')"></el-step>
        </el-steps>
        <div v-if="activeStep === 0" class="checkbox-container" style="justify-content: center">
          <div v-if="!motorConfigData?.externaldevices">
            <h3>{{ $t('ProductDiagnosis.noPeripheral') }}</h3>
          </div>

          <div v-if="motorConfigData?.externaldevices">
            <el-checkbox-group v-model="checkedList">
              <el-row v-for="device in availableDevices" :key="device">
                <el-checkbox :label="device" :value="device"></el-checkbox>
              </el-row>
            </el-checkbox-group>
          </div>

          <hr class="dialog-separator-other">
          <div>
            <el-button class="custom-reset-button" @click="cancelTest">{{ $t('ProductDiagnosis.cancel') }}</el-button>
            <el-button class="button-background-color" @click="firstNextStep">{{
                $t('ProductDiagnosis.nextStep')
              }}
            </el-button>
          </div>

        </div>

        <div v-if="activeStep === 1 ">
          <el-table :data="testItems" style="width: 100%" border :show-header="false">
            <el-table-column type="selection" :min-width="10" :max-width="100">
              <template v-slot="scope">
                <el-checkbox v-if="shouldDisplayRow(scope.row)" v-model="scope.row.checked"
                             @change="() => toggleCategory(scope.row)">
                  {{ scope.row.category }}
                </el-checkbox>
              </template>
            </el-table-column>

            <el-table-column>

              <template v-slot="scope">
                <el-checkbox-group v-model="scope.row.checkedTests">

                  <el-checkbox v-for="item in getFilteredTests(scope.row)" :label="item.label" :key="item.value"
                               :value="item.label">{{ item.label }}
                  </el-checkbox>
                </el-checkbox-group>
              </template>
            </el-table-column>
          </el-table>

          <hr class="dialog-separator-other"/>

          <div>
            <el-button class="custom-reset-button" @click="cancelTest">{{ $t('ProductDiagnosis.cancel') }}</el-button>
            <el-button class="custom-reset-button" @click="previousStep">{{
                $t('ProductDiagnosis.previousStep')
              }}
            </el-button>
            <el-button class="button-background-color" @click="nextStepDebounced">{{
                $t('ProductDiagnosis.nextStep')
              }}
            </el-button>
          </div>
        </div>

        <div v-if="shouldShowTestStep('speedSensorTest')" class="step-same-height">

          <div class="bg-progress">

            <div class="progress-container">
              <div class="step-label">{{ $t('ProductDiagnosis.testStep') }}</div>
              <div class="step-progress">
                <el-progress
                    :percentage="parseFloat(((currentTestStep + 1) / testSteps.length * 100).toFixed(2))"
                    :show-text="false"></el-progress>
              </div>
              <div>{{ (currentTestStep + 1) }} / {{ testSteps.length }}</div>
            </div>

          </div>

          <div class="test-step-container">
            <div>
              <img :src="carSpeedImgUrl" :alt="$t('ProductDiagnosis.speedSensorTest')" class="img-size"/>
            </div>

            <div>
              <div class="main-title">{{ $t('ProductDiagnosis.speedSensorTest') }}</div>
              <div class="test-content">{{ $t('ProductDiagnosis.rotateCrankForward') }}</div>
              <div class="num-result">
                <div class="right-distance">
                  <span class="test-title">{{ $t('ProductDiagnosis.testValue') }}</span><br>
                  <span v-if="shouldShowSpinner(testResultMessages.M2, remainingTime)" class="spinner-container">
  <span class="dot1"></span>
  <span class="dot2"></span>
</span>

                  <span v-else class="test-num">
                    {{ testResultMessages.M2_data ? `${testResultMessages.M2_data} km/h` : '-' }}
                </span>


                </div>
                <div>
                  <span class="test-title">{{ $t('ProductDiagnosis.testResult') }}</span><br/>
                  <span v-if="shouldShowSpinner(testResultMessages.M2, remainingTime)" class="spinner-container">
  <span class="dot1"></span>
  <span class="dot2"></span>
</span>

                  <span v-else :class="testResultClass(testResultMessages.M2)">{{ testResultMessages.M2 }}</span>
                </div>
              </div>
            </div>

            <el-progress :percentage="remainingTimePercentage" type="circle" :format="formatTime"></el-progress>
          </div>

          <hr class="dialog-separator-other"/>

          <div class="button-footer">
            <el-button class="button-background-color" @click="cancelTest">{{
                $t('ProductDiagnosis.cancelTest')
              }}
            </el-button>
            <el-button v-if="isLastStep" class="button-background-color" @click="completeTest">
              {{ $t('ProductDiagnosis.complete') }}
            </el-button>
            <span v-if="isLastStep" style="color: rgb(0,154,68)">{{ $t('ProductDiagnosis.normalCondition') }}</span>
            <el-button v-if="!isLastStep" class="button-background-color" @click="proceedToNextTestStepDebounced">
              {{ $t('ProductDiagnosis.nextStep') }}
            </el-button>
          </div>

        </div>

        <div v-if="shouldShowTestStep('cadenceSensorTest')" class="step-same-height">

          <div class="bg-progress">
            <div class="progress-container">
              <div class="step-label">{{ $t('ProductDiagnosis.testStep') }}</div>
              <div class="step-progress">
                <el-progress
                    :percentage="parseFloat(((currentTestStep + 1) / testSteps.length * 100).toFixed(2))"
                    :show-text="false"></el-progress>
              </div>
              <div>{{ (currentTestStep + 1) }} / {{ testSteps.length }}</div>
            </div>

          </div>
          <div class="test-step-container">
            <div>
              <img :src="carTaPinImgUrl" :alt="$t('ProductDiagnosis.cadenceSensorTest')" class="img-size"/>
            </div>
            <div>
              <div class="main-title">{{ $t('ProductDiagnosis.cadenceSensorTest') }}</div>
              <div class="test-content">{{ $t('ProductDiagnosis.rotateCrankForward') }}</div>
              <div class="num-result">
                <div class="right-distance">
                  <span class="test-title">{{ $t('ProductDiagnosis.testValue') }}</span><br>
                  <span v-if="shouldShowSpinner(testResultMessages.M3, remainingTime)" class="spinner-container">
  <span class="dot1"></span>
  <span class="dot2"></span>
</span>

                  <span v-else class="test-num">
                    {{ testResultMessages.M3_data ? `${testResultMessages.M3_data} rpm` : '-' }}
                </span>

                </div>
                <div>
                  <span class="test-title">{{ $t('ProductDiagnosis.testResult') }}</span><br>
                  <span v-if="shouldShowSpinner(testResultMessages.M3, remainingTime)" class="spinner-container">
  <span class="dot1"></span>
  <span class="dot2"></span>
</span>
                  <span
                      v-else :class="testResultClass(testResultMessages.M3)">{{
                      testResultMessages.M3
                    }}</span>
                </div>
              </div>
            </div>
            <el-progress :percentage="remainingTimePercentage" type="circle" :format="formatTime"></el-progress>

          </div>
          <hr class="dialog-separator-other"/>

          <div class="button-footer">
            <el-button class="button-background-color" @click="cancelTest">{{
                $t('ProductDiagnosis.cancelTest')
              }}
            </el-button>
            <el-button v-if="isLastStep" class="button-background-color" @click="completeTest">
              {{ $t('ProductDiagnosis.complete') }}
            </el-button>
            <span v-if="isLastStep" style="color: rgb(0,154,68)">{{ $t('ProductDiagnosis.normalCondition') }}</span>
            <el-button v-if="!isLastStep" class="button-background-color" @click="proceedToNextTestStepDebounced">
              {{ $t('ProductDiagnosis.nextStep') }}
            </el-button>
          </div>
        </div>

        <div v-if=" shouldShowTestStep('torqueSensorTest')" class="step-same-height">

          <div class="bg-progress">

            <div class="progress-container">
              <div class="step-label">{{ $t('ProductDiagnosis.testStep') }}</div>
              <div class="step-progress">
                <el-progress
                    :percentage="parseFloat(((currentTestStep + 1) / testSteps.length * 100).toFixed(2))"
                    :show-text="false"></el-progress>
              </div>
              <div>{{ (currentTestStep + 1) }} / {{ testSteps.length }}</div>
            </div>

          </div>
          <div class="test-step-container">
            <div>
              <img :src="carLijuImgUrl" :alt="$t('ProductDiagnosis.otherTorqueSensorTest')" class="img-size"/>

            </div>
            <div>
              <div class="main-title">{{ $t('ProductDiagnosis.torqueSensorTest') }}
                <el-tooltip effect="dark" placement="top">
                  <template v-slot:content>
                    <div v-html="$t('ProductDiagnosis.sensorTestDescription')"></div>
                  </template>
                  <el-icon class="invert-icon">
                    <QuestionIcon/>
                  </el-icon>
                </el-tooltip>

              </div>
              <div class="test-content">{{ $t('ProductDiagnosis.applyBrakeWhilePedaling') }}</div>
              <div class="num-result">
                <div class="right-distance test-feedback-container">
                  <span class="test-title">{{ $t('ProductDiagnosis.testValue') }}</span>
                  <span v-if="shouldShowSpinner(testResultMessages.M6, remainingTime)" class="spinner-container">
  <span class="dot1"></span>
  <span class="dot2"></span>
</span>

                  <span v-else class="test-num">
                    {{ testResultMessages.M6_data ? `${testResultMessages.M6_data} Nm` : '-' }}
                </span>

                </div>
                <div class="test-feedback-container">
                  <span class="test-title">{{ $t('ProductDiagnosis.testResult') }}</span>
                  <span v-if="shouldShowSpinner(testResultMessages.M6, remainingTime)" class="spinner-container">
  <span class="dot1"></span>
  <span class="dot2"></span>
</span>
                  <span
                      v-else
                      :class="testResultClass(testResultMessages.M6)">{{
                      testResultMessages.M6
                    }}</span>
                </div>
              </div>
            </div>
            <el-progress :percentage="remainingTimePercentage" type="circle" :format="formatTime"></el-progress>
          </div>
          <hr class="dialog-separator-other"/>

          <div class="button-footer">
            <el-button class="button-background-color" @click="cancelTest">{{ $t('ProductDiagnosis.cancelTest') }}
            </el-button>
            <el-button v-if="isLastStep" class="button-background-color" @click="completeTest">
              {{ $t('ProductDiagnosis.complete') }}
            </el-button>
            <span v-if="isLastStep" style="color: rgb(0,154,68)">{{ $t('ProductDiagnosis.normalCondition') }}</span>
            <el-button v-if="!isLastStep" class="button-background-color" @click="proceedToNextTestStepDebounced">
              {{ $t('ProductDiagnosis.nextStep') }}
            </el-button>
          </div>
        </div>


        <div v-if="shouldShowTestStep('headlightTest')" class="step-same-height">
          <div class="bg-progress">

            <div class="progress-container">
              <div class="step-label">{{ $t('ProductDiagnosis.testStep') }}</div>
              <div class="step-progress">
                <el-progress
                    :percentage="parseFloat(((currentTestStep + 1) / testSteps.length * 100).toFixed(2))"
                    :show-text="false"></el-progress>
              </div>
              <div>{{ (currentTestStep + 1) }} / {{ testSteps.length }}</div>
            </div>

          </div>
          <div class="test-step-container">
            <div>
              <img :src="carBigLightImgUrl" :alt="$t('ProductDiagnosis.headlightTest')" class="img-size"/>
            </div>
            <div>
              <div class="main-title">{{ $t('ProductDiagnosis.headlightTest') }}</div>
              <div class="test-content">{{ $t('ProductDiagnosis.hostComputerLightOnCommand') }}</div>
              <span class="test-title">{{ $t('ProductDiagnosis.testResult') }}</span>&nbsp;&nbsp;&nbsp;

              <span
                  v-if="testSteps[currentTestStep]"
                  :class="lightResult === $t('ProductDiagnosis.success') ? 'passed' :  'failed'">{{
                  lightResult
                }}</span>

              <div class="top-distance">
                <!-- 添加灯亮和灯灭按钮 -->
                <el-button class="custom-reset-button right-distance"
                           @click="setTestResult($t('ProductDiagnosis.success'))">
                  {{ $t('ProductDiagnosis.lightOn') }}
                </el-button>
                <el-button class="custom-reset-button" @click="setTestResult($t('ProductDiagnosis.notPassed'))">
                  {{ $t('ProductDiagnosis.lightOff') }}
                </el-button>
              </div>

            </div>
            <el-progress :percentage="remainingTimePercentage" type="circle" :format="formatTime"></el-progress>
          </div>
          <hr class="dialog-separator-other">
          <div class="button-footer">
            <el-button class="button-background-color" @click="cancelTest">{{
                $t('ProductDiagnosis.cancelTest')
              }}
            </el-button>
            <el-button v-if="isLastStep" class="button-background-color" @click="completeTest">
              {{ $t('ProductDiagnosis.complete') }}
            </el-button>
            <span v-if="isLastStep" style="color: rgb(0,154,68)">{{ $t('ProductDiagnosis.normalCondition') }}</span>
            <el-button v-if="!isLastStep" class="button-background-color" @click="proceedToNextTestStepDebounced">
              {{ $t('ProductDiagnosis.nextStep') }}
            </el-button>
          </div>
        </div>

        <div v-if="shouldShowTestStep('brakeSensorTest')" class="step-same-height">

          <div class="bg-progress">

            <div class="progress-container">
              <div class="step-label">{{ $t('ProductDiagnosis.testStep') }}</div>
              <div class="step-progress">
                <el-progress
                    :percentage="parseFloat(((currentTestStep + 1) / testSteps.length * 100).toFixed(2))"
                    :show-text="false"></el-progress>
              </div>
              <div>{{ (currentTestStep + 1) }} / {{ testSteps.length }}</div>
            </div>

          </div>
          <div class="test-step-container">
            <div>
              <img :src="carShacheImgUrl" :alt="$t('ProductDiagnosis.brakeSensorTest')" class="img-size"/>

            </div>
            <div>
              <div class="main-title">{{ $t('ProductDiagnosis.brakeSensorTest') }}</div>
              <div class="test-content">{{ $t('ProductDiagnosis.applyBrake') }}</div>
              <span class="test-title">{{ $t('ProductDiagnosis.testResult') }}</span>&nbsp;&nbsp;&nbsp;
              <span v-if="shouldShowSpinner(testResultMessages.M5, remainingTime)" class="spinner-container">
  <span class="dot1"></span>
  <span class="dot2"></span>
</span>
              <span
                  v-else :class="testResultClass(testResultMessages.M5)">{{ testResultMessages.M5 }}</span>
            </div>
            <el-progress :percentage="remainingTimePercentage" type="circle" :format="formatTime"></el-progress>
          </div>
          <hr class="dialog-separator-other"/>

          <div class="button-footer">
            <el-button class="button-background-color" @click="cancelTest">{{
                $t('ProductDiagnosis.cancelTest')
              }}
            </el-button>
            <el-button v-if="isLastStep" class="button-background-color" @click="completeTest">
              {{ $t('ProductDiagnosis.complete') }}
            </el-button>
            <span v-if="isLastStep" style="color: rgb(0,154,68)">{{ $t('ProductDiagnosis.normalCondition') }}</span>
            <el-button v-if="!isLastStep" class="button-background-color" @click="proceedToNextTestStepDebounced">
              {{ $t('ProductDiagnosis.nextStep') }}
            </el-button>
          </div>
        </div>

        <div v-if=" shouldShowTestStep('gearSensorTest')" class="step-same-height">

          <div class="bg-progress">

            <div class="progress-container">
              <div class="step-label">{{ $t('ProductDiagnosis.testStep') }}</div>
              <div class="step-progress">
                <el-progress
                    :percentage="parseFloat(((currentTestStep + 1) / testSteps.length * 100).toFixed(2))"
                    :show-text="false"></el-progress>
              </div>
              <div>{{ (currentTestStep + 1) }} / {{ testSteps.length }}</div>
            </div>

          </div>
          <div class="test-step-container">
            <div>
              <img :src="carWheelImgUrl" :alt="$t('ProductDiagnosis.gearSensorTest')" class="img-size"/>

            </div>
            <div>
              <div class="main-title">{{ $t('ProductDiagnosis.gearSensorTest') }}</div>
              <div class="test-content">{{ $t('ProductDiagnosis.shiftGearsWhileRotatingCrank') }}</div>
              <br>
              <span class="test-title">{{ $t('ProductDiagnosis.testResult') }}</span><br>
              <span v-if="shouldShowSpinner(testResultMessages.M4, remainingTime)" class="spinner-container">
  <span class="dot1"></span>
  <span class="dot2"></span>
</span>
              <span
                  v-else :class="testResultClass(testResultMessages.M4)">{{ testResultMessages.M4 }}</span>
            </div>
            <el-progress :percentage="remainingTimePercentage" type="circle" :format="formatTime"></el-progress>
          </div>
          <hr class="dialog-separator-other"/>

          <div class="button-footer">
            <el-button class="button-background-color" @click="cancelTest">{{
                $t('ProductDiagnosis.cancelTest')
              }}
            </el-button>
            <el-button v-if="isLastStep" class="button-background-color" @click="completeTest">
              {{ $t('ProductDiagnosis.complete') }}
            </el-button>
            <span v-if="isLastStep" style="color: rgb(0,154,68)">{{ $t('ProductDiagnosis.normalCondition') }}</span>
            <el-button v-if="!isLastStep" class="button-background-color" @click="proceedToNextTestStepDebounced">
              {{ $t('ProductDiagnosis.nextStep') }}
            </el-button>
          </div>
        </div>

        <div v-if="shouldShowTestStep('throttleSignalTest')" class="step-same-height">

          <div class="bg-progress">

            <div class="progress-container">
              <div class="step-label">{{ $t('ProductDiagnosis.testStep') }}</div>
              <div class="step-progress">
                <el-progress
                    :percentage="parseFloat(((currentTestStep + 1) / testSteps.length * 100).toFixed(2))"
                    :show-text="false"></el-progress>
              </div>
              <div>{{ (currentTestStep + 1) }} / {{ testSteps.length }}</div>
            </div>

          </div>
          <div class="test-step-container">
            <div>
              <img :src="carZhuanbaImgUrl" :alt="$t('ProductDiagnosis.throttleSignalTest')" class="img-size"/>

            </div>
            <div>
              <div class="main-title">{{ $t('ProductDiagnosis.throttleSignalTest') }}</div>
              <div class="test-content">{{ $t('ProductDiagnosis.pullThrottle') }}</div>
              <div class="num-result">
                <div class="right-distance test-feedback-container">
                  <span class="test-title">{{ $t('ProductDiagnosis.testValue') }}</span>
                  <span v-if="shouldShowSpinner(testResultMessages.M7, remainingTime)" class="spinner-container">
  <span class="dot1"></span>
  <span class="dot2"></span>
</span>

                  <span v-else class="test-num">
                    {{ testResultMessages.M7_data ? `${testResultMessages.M7_data} V` : '-' }}
                </span>

                </div>
                <div class="test-feedback-container">
                  <span class="test-title">{{ $t('ProductDiagnosis.testResult') }}</span>
                  <span v-if="shouldShowSpinner(testResultMessages.M7, remainingTime)" class="spinner-container">
  <span class="dot1"></span>
  <span class="dot2"></span>
</span>
                  <span
                      v-else :class="testResultClass(testResultMessages.M7)">{{
                      testResultMessages.M7
                    }}</span>
                </div>
              </div>
            </div>
            <el-progress :percentage="remainingTimePercentage" type="circle" :format="formatTime"></el-progress>
          </div>
          <hr class="dialog-separator-other"/>

          <div class="button-footer">
            <el-button class="button-background-color" @click="cancelTest">{{
                $t('ProductDiagnosis.cancelTest')
              }}
            </el-button>
            <el-button v-if="isLastStep" class="button-background-color" @click="completeTest">
              {{ $t('ProductDiagnosis.complete') }}
            </el-button>
            <span v-if="isLastStep" style="color: rgb(0,154,68)">{{ $t('ProductDiagnosis.normalCondition') }}</span>
            <el-button v-if="!isLastStep" class="button-background-color" @click="proceedToNextTestStepDebounced">
              {{ $t('ProductDiagnosis.nextStep') }}
            </el-button>
          </div>
        </div>

        <div v-if="shouldShowTestStep('motorDriveTest')" class="step-same-height">

          <div class="bg-progress">

            <div class="progress-container">
              <div class="step-label">{{ $t('ProductDiagnosis.testStep') }}</div>
              <div class="step-progress">
                <el-progress
                    :percentage="parseFloat(((currentTestStep + 1) / testSteps.length * 100).toFixed(2))"
                    :show-text="false"></el-progress>
              </div>
              <div>{{ (currentTestStep + 1) }} / {{ testSteps.length }}</div>
            </div>

          </div>
          <div class="test-step-container">
            <div>
              <div class="placeholder-text">
                {{ $t('ProductDiagnosis.automaticTestSafetyNotice') }}
              </div>
            </div>
            <div>
              <div class="main-title">{{ $t('ProductDiagnosis.motorDriveTest') }}</div>
              <div class="num-result">
                <div class="right-distance align-num-res">
                  <span class="test-title">{{ $t('ProductDiagnosis.testValue') }}</span>
                  <span v-if="shouldShowSpinner(testResultMessages.M9, remainingTime)" class="spinner-container">
  <span class="dot1"></span>
  <span class="dot2"></span>
</span>
                  <span v-else class="test-num" :style="{ color: testResultMessages.M9_data ? 'red' : 'inherit' }">
                    {{ testResultMessages.M9_data ? `Error${testResultMessages.M9_data}` : '-' }}
                </span>

                </div>
                <div>
                  <span class="test-title align-num-res">{{ $t('ProductDiagnosis.testResult') }}</span>
                  <span v-if="shouldShowSpinner(testResultMessages.M9, remainingTime)" class="spinner-container">
  <span class="dot1"></span>
  <span class="dot2"></span>
</span>
                  <span
                      v-else :class="testResultClass(testResultMessages.M9)">{{
                      testResultMessages.M9
                    }}</span>
                </div>
              </div>
            </div>
            <el-progress :percentage="remainingTimePercentage" type="circle" :format="formatTime"></el-progress>
          </div>
          <hr class="dialog-separator-other"/>
          <div class="button-footer">
            <el-button class="button-background-color" @click="cancelTest">{{
                $t('ProductDiagnosis.cancelTest')
              }}
            </el-button>
            <el-button v-if="isLastStep" class="button-background-color" @click="completeTest">
              {{ $t('ProductDiagnosis.complete') }}
            </el-button>
            <span v-if="isLastStep" style="color: rgb(0,154,68)">{{ $t('ProductDiagnosis.normalCondition') }}</span>
            <el-button v-if="!isLastStep" class="button-background-color" @click="proceedToNextTestStepDebounced">
              {{ $t('ProductDiagnosis.nextStep') }}
            </el-button>
          </div>
        </div>

        <el-dialog
            :title="$t('ProductDiagnosis.hint')"
            style="text-align: left;"
            v-model="showBatteryCheckModal"
            :width="500"
        >
          <div style="text-align: left">{{ $t('ProductDiagnosis.ensureBatteryOn') }}</div>

          <div class="dialog-footer">
            <el-button class="button-background-color" @click="skipMotorDriveTest">
              {{ $t('ProductDiagnosis.skipMotorDriveTest') }}
            </el-button>
            <el-button class="button-background-color" @click="confirmMotorDriveTest">
              {{ $t('ProductDiagnosis.confirmMotorDriveTest') }}
            </el-button>
          </div>

        </el-dialog>

        <div v-if="shouldShowTestStep('motorTempTest')" class="step-same-height">

          <div class="bg-progress">

            <div class="progress-container">
              <div class="step-label">{{ $t('ProductDiagnosis.testStep') }}</div>
              <div class="step-progress">
                <el-progress
                    :percentage="parseFloat(((currentTestStep + 1) / testSteps.length * 100).toFixed(2))"
                    :show-text="false"></el-progress>
              </div>
              <div>{{ (currentTestStep + 1) }} / {{ testSteps.length }}</div>
            </div>

          </div>
          <div class="test-step-container">
            <div class="placeholder-text">
              {{ $t('ProductDiagnosis.automaticTestSafetyNotice') }}
            </div>
            <div>
              <div class="main-title">{{ $t('ProductDiagnosis.motorTempTest') }}</div>
              <div class="num-result">
                <div class="right-distance align-num-res">
                  <span class="test-title">{{ $t('ProductDiagnosis.testValue') }}</span>
                  <span v-if="shouldShowSpinner(testResultMessages.M8, remainingTime)" class="spinner-container">
  <span class="dot1"></span>
  <span class="dot2"></span>
</span>

                  <span v-else class="test-num">
                    {{ testResultMessages.M8_data ? `${testResultMessages.M8_data} ℃` : '-' }}
                </span>

                </div>
                <div>
                  <span class="test-title align-num-res">{{ $t('ProductDiagnosis.testResult') }}</span>
                  <span v-if="shouldShowSpinner(testResultMessages.M8, remainingTime)" class="spinner-container">
  <span class="dot1"></span>
  <span class="dot2"></span>
</span>
                  <span
                      v-else :class="testResultClass(testResultMessages.M8)">{{
                      testResultMessages.M8
                    }}</span>
                </div>
              </div>
            </div>
            <el-progress :percentage="remainingTimePercentage" type="circle" :format="formatTime"></el-progress>
          </div>
          <hr class="dialog-separator-other"/>
          <div class="button-footer">
            <el-button class="button-background-color" @click="cancelTest">{{
                $t('ProductDiagnosis.cancelTest')
              }}
            </el-button>
            <el-button v-if="isLastStep" class="button-background-color" @click="completeTest">
              {{ $t('ProductDiagnosis.complete') }}
            </el-button>
            <span v-if="isLastStep" style="color: rgb(0,154,68)">{{ $t('ProductDiagnosis.normalCondition') }}</span>
            <el-button v-if="!isLastStep" class="button-background-color" @click="proceedToNextTestStepDebounced">
              {{ $t('ProductDiagnosis.nextStep') }}
            </el-button>
          </div>
        </div>

        <div v-if="shouldShowTestStep('appCommunicationTest')" class="step-same-height">

          <div class="bg-progress">

            <div class="progress-container">
              <div class="step-label">{{ $t('ProductDiagnosis.testStep') }}</div>
              <div class="step-progress">
                <el-progress
                    :percentage="parseFloat(((currentTestStep + 1) / testSteps.length * 100).toFixed(2))"
                    :show-text="false"></el-progress>
              </div>
              <div>{{ (currentTestStep + 1) }} / {{ testSteps.length }}</div>
            </div>

          </div>
          <div class="test-step-container">
            <div>
              <!--              <img :src="carZhuanbaImgUrl" :alt="$t('ProductDiagnosis.appCommunicationTest')" class="img-size"/>-->

            </div>
            <div>
              <div class="main-title">{{ $t('ProductDiagnosis.appCommunicationTest') }}</div>
              <div class="test-content">{{ $t('ProductDiagnosis.connectApp') }}</div>
              <span class="test-title">{{ $t('ProductDiagnosis.testResult') }}</span>&nbsp;&nbsp;&nbsp;
              <span v-if="shouldShowSpinner(testResultMessages.D2, remainingTime)" class="spinner-container">
  <span class="dot1"></span>
  <span class="dot2"></span>
</span>
              <span v-else
                    :class="testResultClass(testResultMessages.D2)">{{
                  testResultMessages.D2
                }}</span>
            </div>
            <el-progress :percentage="remainingTimePercentage" type="circle" :format="formatTime"></el-progress>
          </div>
          <hr class="dialog-separator-other"/>

          <div class="button-footer">
            <el-button class="button-background-color" @click="cancelTest">{{
                $t('ProductDiagnosis.cancelTest')
              }}
            </el-button>

            <el-button v-if="isLastStep" class="button-background-color" @click="completeTest">
              {{ $t('ProductDiagnosis.complete') }}
            </el-button>
            <span v-if="isLastStep" style="color: rgb(0,154,68)">{{ $t('ProductDiagnosis.normalCondition') }}</span>
            <el-button v-if="!isLastStep" class="button-background-color" @click="proceedToNextTestStepDebounced">
              {{ $t('ProductDiagnosis.nextStep') }}
            </el-button>

          </div>
        </div>

        <div v-if="shouldShowTestStep('buttonTest')" class="step-same-height">

          <div class="bg-progress">

            <div class="progress-container">
              <div class="step-label">{{ $t('ProductDiagnosis.testStep') }}</div>
              <div class="step-progress">
                <el-progress
                    :percentage="parseFloat(((currentTestStep + 1) / testSteps.length * 100).toFixed(2))"
                    :show-text="false"></el-progress>
              </div>
              <div>{{ (currentTestStep + 1) }} / {{ testSteps.length }}</div>
            </div>

          </div>
          <div class="test-step-container">
            <!-- 当前按钮的图片居左显示 -->
            <div v-if="displayedButtons.length > 0" class="image-container">
              <img :src="displayedButtons[currentButtonIndex].imgUrl"
                   :alt="displayedButtons[currentButtonIndex].name" class="img-size"/>
            </div>

            <div>
              <div class="main-title">{{ $t('ProductDiagnosis.buttonTest') }}</div>
              <div class="test-content">{{ $t('ProductDiagnosis.pressButtonToTest') }}</div>
              <!-- 循环渲染所有按钮 -->
              <div class="buttons-container">
                <div v-for="(button, index) in displayedButtons" :key="`button-${index}`" class="button-item">
                  <el-button @click="handleButtonClick(index)" class="custom-reset-button">
                    <!--                    {{button.name}}-->
                    <img :src="button.imgUrl"
                         :alt="button.name"
                         class="button-icon"/>{{
                      button.name
                    }}
                  </el-button>
                  <!-- 在此处添加检测状态的逻辑 -->
                  <div v-if="button.status === 'detecting'" class="anjian-detecting">{{
                      $t('ProductDiagnosis.detecting')
                    }}
                  </div>
                  <div v-else-if="button.status === 'passed'" class="anjian-passed">{{
                      $t('ProductDiagnosis.passed')
                    }}
                  </div>
                  <div v-else-if="button.status === 'failed'" class="anjian-failed">{{
                      $t('ProductDiagnosis.notPassed')
                    }}
                  </div>

                  <!-- 为非当前检测项显示待检测状态 -->
                  <div v-else class="anjian-waiting">{{ $t('ProductDiagnosis.waiting') }}</div>
                </div>
              </div>
            </div>
            <!-- 单独渲染当前按钮的图片 -->
            <div class="status-and-timer">
              <el-progress :percentage="remainingTimePercentage" type="circle" :format="formatTime"></el-progress>
            </div>

          </div>
          <hr class="dialog-separator-other"/>

          <div class="button-footer">
            <el-button class="button-background-color" @click="cancelTest">{{
                $t('ProductDiagnosis.cancelTest')
              }}
            </el-button>
            <el-button v-if="isLastStep" class="button-background-color" @click="completeTest">
              {{ $t('ProductDiagnosis.complete') }}
            </el-button>
            <span v-if="isLastStep" style="color: rgb(0,154,68)">{{ $t('ProductDiagnosis.normalCondition') }}</span>
            <el-button v-if="!isLastStep" class="button-background-color" @click="proceedToNextTestStepDebounced">
              {{ $t('ProductDiagnosis.nextStep') }}
            </el-button>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import EventBus from "@/eventBus";
import {data} from "autoprefixer";
import {getDynamicManualData, getDynamicTestData, recordOperation} from "@/api/api";
import QuestionIcon from "@/components/QuestionIcon.vue";
import EmptySlot from "@/components/EmptySlot.vue";
import dialogMixin from "@/mixins/dialogMixin";

export default {
  mixins: [dialogMixin],
  components: {EmptySlot, QuestionIcon},
  emits: ['complete-test', 'cancel-test', 'start-test', 'update-dynamic-data'],
  data() {
    return {
      carSpeedImgUrl: "https://link-public-1310393141.cos.ap-shanghai.myqcloud.com/images/car-speed.gif",
      carTaPinImgUrl: "https://link-public-1310393141.cos.ap-shanghai.myqcloud.com/images/car-tapin.gif",
      carLijuImgUrl: "https://link-public-1310393141.cos.ap-shanghai.myqcloud.com/images/car-liju.gif",
      carBigLightImgUrl: require("@/assets/image/car-biglight.gif"),
      carShacheImgUrl: "https://link-public-1310393141.cos.ap-shanghai.myqcloud.com/images/car-shache.gif",
      carWheelImgUrl: "https://link-public-1310393141.cos.ap-shanghai.myqcloud.com/images/car-wheel.gif",
      carZhuanbaImgUrl: "https://link-public-1310393141.cos.ap-shanghai.myqcloud.com/images/car-zhuanba.gif",

      noDeviceConnectImgUrl: require("@/assets/noDeviceConnect.webp"),

      testStarted: '',
      checkedList: [this.$t('ProductDiagnosis.cadenceSensor'), this.$t('ProductDiagnosis.gearSensor'), this.$t('ProductDiagnosis.brakeSensor'), this.$t('ProductDiagnosis.torqueSensor'), this.$t('ProductDiagnosis.throttle')],

      activeStep: 0,

      motorCheckedList: [],
      dashboardCheckedList: [],

      testItems: [
        {
          category: this.$t('ProductDiagnosis.motor'),
          checked: false,  // 这里添加了checked属性
          tests: [
            {label: this.$t('ProductDiagnosis.speedSensorTest'), value: 6, id: 'speedSensorTest', itemid: 'M2'},
            {label: this.$t('ProductDiagnosis.cadenceSensorTest'), value: 3, id: 'cadenceSensorTest', itemid: 'M3'},
            {label: this.$t('ProductDiagnosis.torqueSensorTest'), value: 4, id: 'torqueSensorTest', itemid: 'M6'},
            {label: this.$t('ProductDiagnosis.headlightTest'), value: 7, id: 'headlightTest', itemid: 'M1'},
            {label: this.$t('ProductDiagnosis.brakeSensorTest'), value: 2, id: 'brakeSensorTest', itemid: 'M5'},
            {label: this.$t('ProductDiagnosis.gearSensorTest'), value: 1, id: 'gearSensorTest', itemid: 'M4'},
            {label: this.$t('ProductDiagnosis.throttleSignalTest'), value: 5, id: 'throttleSignalTest', itemid: 'M7'},
            {label: this.$t('ProductDiagnosis.motorDriveTest'), value: 8, id: 'motorDriveTest', itemid: 'M9'},
            {label: this.$t('ProductDiagnosis.motorTempTest'), value: 9, id: 'motorTempTest', itemid: 'M8'},
          ],
          checkedTests: [],
        },

        {
          category: this.$t('ProductDiagnosis.display'),
          checked: true,  // 这里添加了checked属性
          tests: [
            {label: this.$t('ProductDiagnosis.buttonTest'), value: 9, id: 'buttonTest', itemid: 'D1'},
            {
              label: this.$t('ProductDiagnosis.appCommunicationTest'),
              value: 10,
              id: 'appCommunicationTest',
              itemid: 'D2'
            },
          ],
          checkedTests: [],
        },
      ],

      currentTestStep: 0,
      totalTestStep: 10,
      remainingTime: 30, // 剩余时间（秒）
      totalTime: 30, // 用于计算进度条的总时间（秒）
      displayValueToLabelMap: {
        '10': this.$t('ProductDiagnosis.appCommunicationTest'),
      },
      alwaysShownTests: [6, 7, 8],
      displayAlwaysShownTests: [9],
      testSteps: [], // 动态生成的测试步骤数组
      countdownInterval: null,
      lightResult: null,

      testResultMessages: {}, // 存储每个测试步骤的结果信息

      currentButtonIndex: 0, // 当前测试的按键索引
      buttonTests: [
        {
          name: this.$t('ProductDiagnosis.powerButton'),
          imgUrl: require("@/assets/image/car-dy.gif"),
          status: 'waiting',
          shouldBeDisplayed: false,
          p1: '1'
        },
        {
          name: this.$t('ProductDiagnosis.incrementButton'),
          imgUrl: require("@/assets/image/car-jia.gif"),
          status: 'waiting',
          shouldBeDisplayed: false,
          p1: '2'
        },
        {
          name: this.$t('ProductDiagnosis.decrementButton'),
          imgUrl: require("@/assets/image/car-jian.gif"),
          status: 'waiting',
          shouldBeDisplayed: false,
          p1: '3'
        },
        {
          name: this.$t('ProductDiagnosis.walkButton'),
          imgUrl: require("@/assets/image/car-tx.gif"),
          status: 'waiting',
          shouldBeDisplayed: false,
          p1: '6'
        },
        {
          name: this.$t('ProductDiagnosis.headlightButton'),
          imgUrl: require("@/assets/image/car-biglight.gif"),
          status: 'waiting',
          shouldBeDisplayed: false,
          p1: '7'
        },
        {
          name: this.$t('ProductDiagnosis.modeButton'),
          imgUrl: require("@/assets/image/car-i.gif"),
          status: 'waiting',
          shouldBeDisplayed: false,
          p1: '4'
        },
        {
          name: this.$t('ProductDiagnosis.iButton'),
          imgUrl: require("@/assets/image/car-i.gif"),
          status: 'waiting',
          shouldBeDisplayed: false,
          p1: '5'
        },
      ],
      isInButtonTest: false, // 新状态，表示是否正在进行按键检测
      isLastStep: false, // 控制是否为最后一步
      showReportTable: false, // 控制报告表格显示的属性
      // 表格的数据
      tableData: [],
      testCompleted: false, // 本地状态，表明测试是否完成
      showBatteryCheckModal: false, // 新增状态控制电池检查模态框是否显示

      isTesting: false,   // 用于表示测试是否正在进行

      unitMapping: {
        [this.$t('ProductDiagnosis.speedSensorTest')]: 'km/h',
        [this.$t('ProductDiagnosis.cadenceSensorTest')]: 'rpm',
        [this.$t('ProductDiagnosis.torqueSensorTest')]: 'Nm',
        [this.$t('ProductDiagnosis.throttleSignalTest')]: 'V',
        [this.$t('ProductDiagnosis.motorTempTest')]: '℃',
        // 添加其他测试项及其单位
      },
      testResultReceived: false,
      allDevices: [], // 初始化为空数组
      proceedToNextTestStepDebounced: null,
      nextStepDebounced: null,
      isDisplayInfoReceived: false,
    }
  },
  props: {
    motorTypeAndModelData: Object
  },
  // 使用防抖包装您的函数
  async created() {
    this.nextStepDebounced = this.debounce(this.nextStep, 300);
    this.proceedToNextTestStepDebounced = this.debounce(this.proceedToNextTestStep, 300);
    this.initializeAllDevices();
    this.initializeTestItems();
    await this.loadSubPermissions();
  },
  watch: {
    // 监听语言变化并更新 availableDevices
    '$i18n.locale': function () {
      this.initializeAllDevices();
      this.initializeTestItems(); // 语言切换时重新初始化测试项目
      this.generateTestSteps(); // 确保生成的测试步骤也是最新的
    },
    isDisplayConnected(newValue) {
      if (newValue) {
        this.initializeTestItems();
      }
    },
    motorConfigData: {
      handler(newVal) {
        if (newVal && newVal.externaldevices) {
          this.initializeAllDevices();
        }
      },
      deep: true,  // 深度监听对象内部变化
      immediate: true  // 立即执行一次，确保初始加载也被处理
    },
  },

  mounted() {

    this.initializeAllDevices();
    this.initializeTestItems();
    //用于点击重新连接清空数据
    EventBus.on('reset-all-data', this.clearData);

    // 动态设置testItems基于仪表板的连接状态
    this.initializeTestItems();

    EventBus.on('DisplayInfoResponse', this.handleDisplayInfoResponse);

    // 假设您有一个数组定义了所有的 itemid
    const testItemIds = ["M1", "M2", "M3", "M4", "M5", "M6", "M7", "M8", "M9"];

    // 为每个测试项设置监听器
    testItemIds.forEach((itemid) => {
      EventBus.on(`DeviceTest_${itemid}`, this.handleGenericTestResponse);
    });

    EventBus.on('DeviceTestCancelReceived', this.handleTestCancelResponse);
    EventBus.on('OpenLightResponse', this.handleOpenLightResponse);
    EventBus.on('CloseLightResponse', this.handleCloseLightResponse);
    EventBus.on('DeviceTest_D1', this.handleDashboardTestD1);
    EventBus.on('startMotorAutoTestResponse', this.handleStartMotorAutoTestResponse);
    EventBus.on('RealTimeWsMessage', this.handleStopMotorTestResponse);
    EventBus.on('request-stop-test', this.handleStopTestRequest);
    // 假设您有一个数组定义了所有的 itemid
    const generalDashboardTestItemIds = ["D2", "D3", "D4"]; // 扩展此数组以包含未来的指令

    // 为每个测试项设置监听器
    generalDashboardTestItemIds.forEach((itemid) => {
      EventBus.on(`DeviceTest_${itemid}`, this.handleDashboardTestDx);
    });

    // 设置默认进入时第一个按键为当前检测按键
    this.currentButtonIndex = 0;

    // 可能需要手动触发视图更新
    this.$forceUpdate();

  },
  beforeUnmount() {
    //用于点击重新连接清空数据
    EventBus.off('reset-all-data', this.clearData);

    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
    // 在组件销毁时移除监听器，避免内存泄露
    const testItemIds = ["M1", "M2", "M3", "M4", "M5", "M6", "M7", "M8", "M9"];

    testItemIds.forEach((itemid) => {
      EventBus.off(`DeviceTest_${itemid}`, this.handleGenericTestResponse);
    });

    EventBus.off('DeviceTestCancelReceived', this.handleTestCancelResponse);
    EventBus.off('OpenLightResponse', this.handleOpenLightResponse);
    EventBus.off('CloseLightResponse', this.handleCloseLightResponse);
    EventBus.off('DisplayInfoResponse', this.handleDisplayInfoResponse);
    EventBus.off('DeviceTest_D1', this.handleDashboardTestD1);

    EventBus.off('startMotorAutoTestResponse', this.handleStartMotorAutoTestResponse);
    EventBus.off('RealTimeWsMessage', this.handleStopMotorTestResponse);

    const generalDashboardTestItemIds = ["D2", "D3", "D4"];

    generalDashboardTestItemIds.forEach((itemid) => {
      EventBus.off(`DeviceTest_${itemid}`, this.handleDashboardTestDx);
    });
    EventBus.off('request-stop-test', this.handleStopTestRequest);
  },
  computed: {
    getMotorTypeAndModelData() {
      const { isMotorConnected, isMotorConnecting, motorTypeAndModelData } = this;
      if (isMotorConnected && !isMotorConnecting && motorTypeAndModelData) {
        // 电机在线且数据存在，直接返回数据
        return motorTypeAndModelData;
      }
      // 电机未在线或数据不存在，返回带有默认值的对象
      return {
        motorType: null, // 或者其他适当的默认值，比如 'unknown'
        model: '-' // 默认值，表示模型未获取或电机未在线
      };
    },
    stepCommands() {
      return {
        [this.$t('ProductDiagnosis.speedSensorTest')]: {
          cmd: "DeviceTest",
          itemid: "M2",
          itemname: "",
          p1: "1",
          p2: "100",
          maxTestCount: "50"
        },
        [this.$t('ProductDiagnosis.gearSensorTest')]: {
          cmd: "DeviceTest",
          itemid: "M4",
          itemname: "",
          p1: "1",
          p2: "100",
          maxTestCount: "50"
        },
        [this.$t('ProductDiagnosis.brakeSensorTest')]: {
          cmd: "DeviceTest",
          itemid: "M5",
          itemname: "",
          p1: "1",
          p2: "100",
          maxTestCount: "50"
        },
        [this.$t('ProductDiagnosis.cadenceSensorTest')]: {
          cmd: "DeviceTest",
          itemid: "M3",
          itemname: "",
          p1: "1",
          p2: "100",
          maxTestCount: "50"
        },
        [this.$t('ProductDiagnosis.throttleSignalTest')]: {
          cmd: "DeviceTest",
          itemid: "M7",
          itemname: "",
          p1: "1",
          p2: "5",
          maxTestCount: "50"
        },
        [this.$t('ProductDiagnosis.torqueSensorTest')]: {
          cmd: "DeviceTest",
          itemid: "M6",
          itemname: "",
          p1: "1",
          p2: "100",
          maxTestCount: "50"
        },
        [this.$t('ProductDiagnosis.headlightTest')]: {cmd: "openLight", param: "", itemid: "M1",},
        [this.$t('ProductDiagnosis.motorDriveTest')]: {
          cmd: "DeviceTest",
          itemid: "M9",
          itemname: "",
          p1: "1",
          p2: "100",
          maxTestCount: "50"
        },
        [this.$t('ProductDiagnosis.motorTempTest')]: {
          cmd: "DeviceTest",
          itemid: "M8",
          itemname: "",
          p1: "1",
          p2: "150",
          maxTestCount: "50"
        },

        [this.$t('ProductDiagnosis.buttonTest')]: {
          cmd: "DeviceTest",
          itemid: "D1",
          itemname: "",
          p1: "1",
          p2: "",
          maxTestCount: "10"
        },

        [this.$t('ProductDiagnosis.appCommunicationTest')]: {
          cmd: "DeviceTest",
          itemid: "D2",
          itemname: "",
          p1: "1",
          p2: "100",
          maxTestCount: "6000"
        }
      };
    },
    availableDevices() {
      return this.allDevices;
    },
    showNoDeviceConnected() {

      // 所有设备都未成功连接
      const allDevicesNotConnected = !this.isMotorConnected && !this.isBatteryConnected && !this.isDisplayConnected;
      // 所有设备都在尝试连接
      const allDevicesConnecting = this.isMotorConnecting && this.isBatteryConnecting && this.isDisplayConnecting;

      // 如果所有设备都在连接中，或者都未连接，则返回true
      return allDevicesNotConnected || allDevicesConnecting;
    },
    connectedDevicesDescription() {
      let descriptions = [];
      if (this.isMotorConnected) descriptions.push(this.$t('ProductDiagnosis.motor'));
      if (this.isBatteryConnected) descriptions.push(this.$t('ProductDiagnosis.battery'));
      if (this.isDisplayConnected) descriptions.push(this.$t('ProductDiagnosis.dashboard'));

      // 将数组转换为逗号分隔的字符串，并在末尾添加翻译后的“准备完成，请开始测试”
      return descriptions.length > 0
          ? `${descriptions.join('、')}${this.$t('ProductDiagnosis.testReady')}`
          : this.$t('ProductDiagnosis.connectDevice');
    },
    isStartTestDisabled() {
      // 检查电机或仪表是否连接，并且检查任何设备是否在连接中
      return !(this.isMotorConnected || this.isDisplayConnected) || this.isBatteryConnecting || this.isMotorConnecting || this.isDisplayConnecting;
    },
    isBatteryConnected() {
      if (!this.batteryInfo) {
        return false; // 如果是，认为“电池信息为空”
      }
      return Object.keys(this.batteryInfo).length !== 0;
    },
    batteryInfo() {
      return this.$store.state.batteryInfo;
    },
    // 添加一个计算属性来过滤出需要显示的按钮
    displayedButtons() {
      const filteredButtons = this.buttonTests.filter(button => button.shouldBeDisplayed);

      const commonButtons = [
        {
          name: this.$t('ProductDiagnosis.powerButton'),
          imgUrl: require("@/assets/image/car-dy.gif"),
          status: 'waiting',
          shouldBeDisplayed: false,
          p1: '1'
        },
        {
          name: this.$t('ProductDiagnosis.incrementButton'),
          imgUrl: require("@/assets/image/car-jia.gif"),
          status: 'waiting',
          shouldBeDisplayed: false,
          p1: '2'
        },
        {
          name: this.$t('ProductDiagnosis.decrementButton'),
          imgUrl: require("@/assets/image/car-jian.gif"),
          status: 'waiting',
          shouldBeDisplayed: false,
          p1: '3'
        },
        {
          name: this.$t('ProductDiagnosis.iButton'),
          imgUrl: require("@/assets/image/car-i.gif"),
          status: 'waiting',
          shouldBeDisplayed: false,
          p1: '5'
        },
      ];

      if (filteredButtons.length === 0) {
          return commonButtons;
      }

      return filteredButtons;
    },

    displayedButtonsJson() {
      return JSON.stringify(this.displayedButtons, null, 2);
    },

    data() {
      return data
    },

    remainingTimePercentage() {
      return (this.remainingTime / this.totalTime) * 100;
    },
    isDisplayConnected() {
      return this.$store.state.isDisplayConnected;
    },
    isMotorConnected() {
      return this.$store.state.isMotorConnected;
    },
    isMotorConnecting() {
      return this.$store.state.isMotorConnecting;
    },
    isBatteryConnecting() {
      return this.$store.state.isBatteryConnecting;
    },
    isDisplayConnecting() {
      return this.$store.state.isDisplayConnecting;
    },
    motorConfigData() {
      return this.$store.state.motorConfigData;
    },
    getWs() {
      return this.$store.state.ws;
    },
  },

  methods: {
    handleStopTestRequest(shouldStop) {
      if (shouldStop) {
        this.cancelTest();
      }
    },
    getValueToLabelMap() {
      return {
        '3': this.$t('ProductDiagnosis.cadenceSensor'),
        '1': this.$t('ProductDiagnosis.gearSensor'),
        '2': this.$t('ProductDiagnosis.brakeSensor'),
        '4': this.$t('ProductDiagnosis.torqueSensor'),
        '5': this.$t('ProductDiagnosis.throttle'),
        '9': this.$t('ProductDiagnosis.buttonTest'),
        '10': this.$t('ProductDiagnosis.appCommunicationTest')
      };
    },

    async fetchThresholdsForSteps(steps) {
      try {
        const itemnames = steps.map(step => step.stepName);
        const thresholdsData = await getDynamicTestData(itemnames);

        // 假设返回数据是一个数组，键为 itemname，值为对应的阈值
        let thresholdsMap = {};
        if (Array.isArray(thresholdsData.data)) {
          thresholdsData.data.forEach(data => {
            // 假设 thresholds 是一个数组，我们取第一个值
            if (data.thresholds && data.thresholds.length > 0) {
              thresholdsMap[data.name] = {
                begin: data.thresholds[0].begin,
                end: data.thresholds[0].end
              };
            }
          });
        }
        return thresholdsMap;
      } catch (error) {
        return {};
      }
    },

    async updateStepCommands() {
      // 收集所有的 stepNames
      const steps = Object.keys(this.stepCommands).map(stepName => ({
        stepName,
        command: this.stepCommands[stepName]
      }));

      // 调用新的 API 方法获取所有阈值
      const thresholdsMap = await this.fetchThresholdsForSteps(steps);

      // 更新每个 command 的阈值
      for (let stepName in this.stepCommands) {
        const command = this.stepCommands[stepName];
        const stepThresholds = thresholdsMap[stepName];

        if (command && stepThresholds) {
          command.p1 = String(stepThresholds.begin); // 转换为字符串
          command.p2 = String(stepThresholds.end);   // 转换为字符串
        }
      }
    },

    debounce(func, wait, immediate) {
      let timeout;
      return function () {
        let context = this, args = arguments;
        let later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },
    initializeAllDevices() {
      if (this.motorConfigData && this.motorConfigData.externaldevices) {
        this.allDevices = this.motorConfigData.externaldevices.map(code => this.deviceName(code));
      }
    },
    clearData() {
      if (!this.isTesting) {
        this.tableData = [];
        // 清空其他可能影响设备连接状态的数据
        this.isMotorConnected = false;
        this.isBatteryConnected = false;
        this.isDisplayConnected = false;
        this.showNoDeviceConnected = true;  // 立即显示无设备连接界面
        this.testCompleted = false;
      }
    },
    handleStopMotorTestResponse(response) {
      if (response.code !== 200) {
        console.error("Failed to stop motor test:", response.error);
      }
    },

    handleStartMotorAutoTestResponse(response) {
      if (response.code === 200) {
        const testCommand = this.stepCommands[this.$t('ProductDiagnosis.motorDriveTest')]; // 直接引用 stepCommands 中的命令配置
        if (testCommand) {
          this.sendCommand(testCommand);
        } else {
          console.error("Test command for motor auto test not found in stepCommands.");
        }
      } else {
        console.error("Failed to start motor auto test:", response.error);
      }
    },


    // 用户选择跳过测试
    skipMotorDriveTest() {
      this.showBatteryCheckModal = false; // 关闭模态框
      this.testResultMessages['M9'] =  this.$t('ProductDiagnosis.untested') ;
      this.startCountdown(); // 重新启动倒计时
      // 判断是否已经在测试的最后一步
      if (this.currentTestStep >= this.testSteps.length - 1) {
        this.completeTest(); // 假设这个方法负责处理测试完成的逻辑
      } else {
        // 如果不是最后一步，继续到下一个测试步骤
        this.continueToNextTestStep();
      }
    },

    // 用户确认电量充足，准备进行测试
    confirmMotorDriveTest() {
      this.showBatteryCheckModal = false; // 关闭模态框
      this.startCountdown(); // 重新启动倒计时
      this.proceedToMotorDriveTest(); // 进行电机驱动测试
    },

    proceedToMotorDriveTest() {
      const startTestCommand = {cmd: "startMotorAutoTest", "param": ""};

      // 发送开始测试命令
      this.sendCommand(startTestCommand);
    },

    async saveTestResults() {
      const currentLanguage = this.$store.getters.currentLanguage; // 获取当前语言
      // 清空表格数据
      this.tableData = [];

      // 测试内容与中文名称的映射表
      const itemNameMappingResult = {
        'M1': this.$t('ProductDiagnosis.headlightTest'),
        'M2': this.$t('ProductDiagnosis.speedSensorTest'),
        'M4': this.$t('ProductDiagnosis.gearSensorTest'),
        'M5': this.$t('ProductDiagnosis.brakeSensorTest'),
        'M3': this.$t('ProductDiagnosis.cadenceSensorTest'),
        'M7': this.$t('ProductDiagnosis.throttleSignalTest'),
        'M6': this.$t('ProductDiagnosis.torqueSensorTest'),
        'M9': this.$t('ProductDiagnosis.motorDriveTest'),
        'M8': this.$t('ProductDiagnosis.motorTempTest'),
        'D1': this.$t('ProductDiagnosis.buttonTest'),
        'D2': this.$t('ProductDiagnosis.appCommunicationTest'),
      };
      const itemNameMapping = {
        'M1': '大灯检测',
        'M2': '车速传感器检测',
        'M4': '齿轮传感器检测',
        'M5': '刹车传感器检测',
        'M3': '踏频传感器检测',
        'M7': '转把信号检测',
        'M6': '动态力矩检测',
        'M9': '电机驱动检测',
        'M8': '电机温度检测',
        'D1': '按键测试检测',
        'D2': 'APP通讯检测',
      };

      // 获取所有动态测试项的操作手册数据
      let manualData = [];
      try {
        const response = await getDynamicManualData(1, 100);
        manualData = response.data;

      } catch (error) {
        console.error(this.$t('ProductDiagnosis.fetchManualError'), error);
      }

      let resultsArray = [];
      let index = 0;

      // 合并按键检测结果
      let buttonTestResults = [];

      for (const key in this.testResultMessages) {
        if (!key.endsWith('_data')) {
          // 提取数据
          let testContent = key; // 测试名称
          let result = this.testResultMessages[key] || this.$t('ProductDiagnosis.notPassed'); // 测试结果

          let testDataKey = `${key}_data`; // 测试数据的键名
          let testData = this.testResultMessages[testDataKey] || '-'; // 测试数据

          let itemname = itemNameMapping[testContent] || '';
          let internationalizedItemname = itemNameMappingResult[testContent] || '';

          let type = testContent.startsWith('M') ? 0 : testContent.startsWith('D') ? 1 : undefined;

          // 匹配操作手册数据
          const manualInfo = manualData.find(item => item.product_type === type && item.name === itemname) || {};

          let manual = '-'; // 默认值
          let manual_link = ''; // 默认链接

          if (manualInfo && manualInfo.documents) {
            let motor_type = 0;
            if (this.motorTypeAndModelData?.motorType === 'hub') {
              motor_type = 1;
            }
            const document = manualInfo.documents.find(doc => doc.lang === currentLanguage && doc.type === motor_type) || manualInfo.documents[0];

            if (manualInfo.document_type === 1) {
              // 如果 document_type 为 1，使用 content 作为手册内容
              manual = document.content || '-'; // 如果 content 也不存在，使用 '-'
              manual_link = '-'; // 文本类型不提供链接
            } else if (manualInfo.document_type === 0) {
              // 如果 document_type 为 0，使用文件名和链接
              manual = document.file_name || '-';
              manual_link = document.fileUrl || '-'; // 如果 fileUrl 也不存在，使用 '-'
            }
          }

          // 构建用于表格显示的对象
          let isErrorName;
          if (testContent === 'M1') {
            // 根据lightResult确定大灯检测项M1的测试结果
            isErrorName = this.lightResult === this.$t('ProductDiagnosis.success') ? 0 : 1;
          } else {
            // 其他测试内容的测试结果
            isErrorName = result === this.$t('ProductDiagnosis.passed') ? 0 : result ===  this.$t('ProductDiagnosis.untested') ? 2 : 1;
          }

          if (testContent === 'D1') {
            // 处理按键测试
            buttonTestResults = this.displayedButtons
                .map(button => button.name);
          } else {
            if (internationalizedItemname) { // 仅当测试内容有对应名称时才添加
              // 构建用于表格显示的对象
              resultsArray.push({
                index: ++index,
                module: type === 0 ? this.$t('ProductDiagnosis.motor') : type === 1 ? this.$t('ProductDiagnosis.dashboard') : this.$t('ProductDiagnosis.unknown'),
                testData: testData,
                testContent: internationalizedItemname,
                isErrorName: isErrorName, // 这里假设'异常'是错误状态的标记
                measures: this.$t('ProductDiagnosis.referenceHelpManual'),
                manual: manual, // 假设每行都有一个操作手册的链接
                manual_link: manual_link, // 操作手册的链接
              });
            }
          }

        }
      }

      // 将按键检测结果合并到一个对象中
      if (buttonTestResults.length > 0) {
        const failedButtons = this.displayedButtons.filter(button => button.status !== 'passed').map(button => button.name).join(', ');

        // 匹配操作手册数据
       // const manualInfo = manualData.find(item => item.name === itemNameMapping['D1']) || {}; // 使用 D1 对应的测试名称去匹配
        const manualInfo = manualData.find(item => item.product_type === 1 && item.name === '按键检测') || {};

        let manual = '-'; // 默认值
        let manual_link = ''; // 默认链接

        if (manualInfo && manualInfo.documents) {
          let motor_type = 0;
          if (this.motorTypeAndModelData?.motorType === 'hub') {
            motor_type = 1;
          }
          const document = manualInfo.documents.find(
              doc => doc.lang === currentLanguage && doc.type === motor_type
          ) || manualInfo.documents[0];

          if (manualInfo.document_type === 1) {
            // 如果 document_type 为 1，使用 content 作为手册内容
            manual = document.content || '-'; // 如果 content 也不存在，使用 '-'
            manual_link = '-'; // 文本类型不提供链接
          } else if (manualInfo.document_type === 0) {
            // 如果 document_type 为 0，使用文件名和链接
            manual = document.file_name || '-';
            manual_link = document.fileUrl || '-'; // 如果 fileUrl 也不存在，使用 '-'
          }
        }

        resultsArray.push({
          index: ++index,
          module: this.$t('ProductDiagnosis.dashboard'),
          testData: failedButtons || '-',
          testContent: this.$t('ProductDiagnosis.buttonTest'),
          isErrorName: failedButtons ? 1 : 0,
          measures: this.$t('ProductDiagnosis.referenceHelpManual'),
          manual: manual,
          manual_link: manual_link,
        });
      }

      // 分批加载数据，每批次加载10条数据
      const batchSize = 10;
      for (let i = 0; i < resultsArray.length; i += batchSize) {
        this.tableData.push(...resultsArray.slice(i, i + batchSize));
        await this.$nextTick(); // 确保DOM更新
      }

      this.$emit('update-dynamic-data', this.tableData);
    },

    handleDashboardTestDx(message) {
      const data = this.safeJSONParse(message.data);

      // 如果result为true，则处理data.data
      if (data.result === "true") {
        // 将测试结果更新为“通过”，同时存储data.data的值
        this.testResultMessages[data.itemid] = this.$t('ProductDiagnosis.passed');
        // 例如，将data.data存储为新的键值对，键名可以根据需要定义，如`${data.itemid}-data`
        this.testResultMessages[`${data.itemid}_data`] = data.data;
      } else {
        // 如果result不为true，只更新测试结果为“不通过”
        this.testResultMessages[data.itemid] = this.$t('ProductDiagnosis.notPassed');
        this.testResultMessages[`${data.itemid}_data`] = '';

      }
    },

    handleDashboardTestD1(message) {
      const data = this.safeJSONParse(message.data);
      if (data.itemid !== "D1") {
        return;
      }
      // 确定当前正在测试的按钮
      const currentButton = this.displayedButtons[this.currentButtonIndex];

      if (!currentButton) {
        console.error("No current button found for testing.");
        return;
      }

      // 根据测试结果更新按钮状态
      if (data.result === "true") {
        // 如果测试通过
        currentButton.status = 'passed';
        //this.testResultMessages[currentButton.name] = this.$t('ProductDiagnosis.passed');
      } else {
        // 如果测试未通过
        currentButton.status = 'failed';
        this.testResultMessages[currentButton.name] = this.$t('ProductDiagnosis.notPassed');
      }

      // 可能需要通知Vue强制更新视图，特别是如果使用了对象或数组的索引直接修改数据时
      this.$forceUpdate();
    },

    handleDisplayInfoResponse(response) {
      this.isDisplayInfoReceived = true; // 标记已接收到显示信息
      let displayInfo; // 将displayInfo声明在函数顶部

      if (response && response.data) {
        try {
          displayInfo = JSON.parse(response.data); // 为displayInfo赋值
        } catch (e) {
          console.error('Failed to parse JSON data:', e);
          return; // 如果解析失败，直接返回以防止后续代码执行
        }
      }
      const keyToButtonNameMap = {
        1: this.$t('ProductDiagnosis.powerButton'),
        2: this.$t('ProductDiagnosis.incrementButton'),
        3: this.$t('ProductDiagnosis.decrementButton'),
        4: this.$t('ProductDiagnosis.modeButton'),
        5: this.$t('ProductDiagnosis.iButton'),
        6: this.$t('ProductDiagnosis.walkButton'),
        7: this.$t('ProductDiagnosis.headlightButton'),
      };

      // 确保displayInfo已定义并且是对象
      if (displayInfo) {
        this.buttonTests.forEach(button => {
          button.shouldBeDisplayed = false; // 默认设置为不显示
        });

        // 根据Numberofkeys的值和KeyXvalue更新显示的按钮
        for (let i = 1; i <= 10; i++) {
          const keyValue = displayInfo[`Key${i}value`];
          if (keyValue === 0) {
            continue; // 如果 keyValue 未定义，则跳过当前循环
          }
          const buttonName = keyToButtonNameMap[keyValue];
          if (buttonName) {
            const button = this.buttonTests.find(button => button.name === buttonName);
            if (button) {
              button.shouldBeDisplayed = true;
            }
          }
        }
      }
    },

    completeTest() {
      // 直接从当前测试步骤获取 itemid
      const currentItemId = this.testSteps[this.currentTestStep]?.id;
      if (currentItemId === "motorDriveTest") {
        const stopTestCommand = {cmd: "stopMotorTest", param: ""};
        this.sendCommand(stopTestCommand);
      }
      clearInterval(this.countdownInterval); // 停止倒计时
      // 调用存储逻辑
      this.saveTestResults();
      // 设置本地状态为完成
      this.testCompleted = true;
      // 通知父组件测试已完成
      this.$emit('complete-test');
      this.testStarted = false;
      EventBus.emit('test-status-change', false); // 发送测试结束的状态
      this.isTesting = false;
    },

    // 新增方法
    initializeTestItems() {
      let testItems = [
        {
          category: this.$t('ProductDiagnosis.motor'),
          checked: false,
          tests: [
            {label: this.$t('ProductDiagnosis.speedSensorTest'), value: 6, id: 'speedSensorTest', itemid: 'M2'},
            {label: this.$t('ProductDiagnosis.cadenceSensorTest'), value: 3, id: 'cadenceSensorTest', itemid: 'M3'},
            {label: this.$t('ProductDiagnosis.torqueSensorTest'), value: 4, id: 'torqueSensorTest', itemid: 'M6'},
            {label: this.$t('ProductDiagnosis.headlightTest'), value: 7, id: 'headlightTest', itemid: 'M1'},
            {label: this.$t('ProductDiagnosis.brakeSensorTest'), value: 2, id: 'brakeSensorTest', itemid: 'M5'},
            {label: this.$t('ProductDiagnosis.gearSensorTest'), value: 1, id: 'gearSensorTest', itemid: 'M4'},
            {label: this.$t('ProductDiagnosis.throttleSignalTest'), value: 5, id: 'throttleSignalTest', itemid: 'M7'},
            {label: this.$t('ProductDiagnosis.motorDriveTest'), value: 8, id: 'motorDriveTest', itemid: 'M9'},
          ],
          checkedTests: [],
        }
      ];

      if (this.isDisplayConnected) {
        const dashboardTests = [
          {label: this.$t('ProductDiagnosis.buttonTest'), value: 9, id: 'buttonTest', itemid: 'D1'},
          {
            label: this.$t('ProductDiagnosis.appCommunicationTest'),
            value: 10,
            id: 'appCommunicationTest',
            itemid: 'D2'
          },
        ];

        // 将所有仪表板测试项的值加入到checkedTests数组
        const checkedDashboardTests = dashboardTests.map(test => test.label);

        testItems.push({
          category: this.$t('ProductDiagnosis.display'),
          checked: true, // 假设仪表盘测试项默认选中
          tests: dashboardTests,
          checkedTests: checkedDashboardTests, // 使所有仪表板测试项默认选中
        });
        this.checkedList.push(...checkedDashboardTests);
      }

      // 电机部分的选项根据motorConfigData?.externaldevices更新
      if (this.motorConfigData && this.motorConfigData.externaldevices) {
        // 注意：这里假设externaldevices数组中存储的是字符串形式的数字
        this.checkedList = this.motorConfigData.externaldevices.map(Number) // 将字符串转换为数字
            .map(value => {
              const testItem = testItems[0].tests.find(test => test.value === value);
              return testItem ? testItem.label : null;
            })
            .filter(label => label !== null);
      }

      // 动态添加电机温度检测项，如果有温度传感器
      if (this.motorConfigData && this.motorConfigData.MotorTemperatureProtectionEnable === '1') {
        const motorTempTest = {
          label: this.$t('ProductDiagnosis.motorTempTest'),
          value: 9,
          id: 'motorTempTest',
          itemid: 'M8'
        };
        testItems[0].tests.push(motorTempTest);
      }
      this.testItems = testItems;
    },
    setTestResult(result) {
      // 假设有一个方法或变量可以设置当前测试步骤的结果
      this.lightResult = result;
    },
    handleCloseLightResponse(message) {
      // 处理开灯指令的响应
      console.debug(this.$t('ProductDiagnosis.closeLightResponse'), message);
    },
    handleOpenLightResponse(message) {
      // 处理开灯指令的响应
      console.debug(this.$t('ProductDiagnosis.openLightResponse'), message);
    },
    handleTestCancelResponse(message) {
      if (message.code === 200) {
        console.debug(this.$t('ProductDiagnosis.testCancelSuccess'));
      }
    },
    safeJSONParse(str) {
      try {
        return JSON.parse(str);
      } catch (e) {
        console.error('Error parsing JSON:', e);
        return null; // 或者根据需要返回一个默认值
      }
    },

    handleGenericTestResponse(message) {
      const data = this.safeJSONParse(message.data);
      this.testResultReceived = true; // 将标志设置为true，表示已收到结果
      // 如果result为true，则处理data.data
      if (data.result === "true") {
        // 将测试结果更新为“通过”，同时存储data.data的值
        this.testResultMessages[data.itemid] = this.$t('ProductDiagnosis.passed');
        // 例如，将data.data存储为新的键值对，键名可以根据需要定义，如`${data.itemid}-data`
        this.testResultMessages[`${data.itemid}_data`] = data.data;
      } else {
        // 如果result不为true，只更新测试结果为“不通过”
        this.testResultMessages[data.itemid] = this.$t('ProductDiagnosis.notPassed');

        if (data.itemid === "M9") {
          this.testResultMessages[`${data.itemid}_data`] = data.data;
        } else {
          if (data.data === 'Test canceled') {
            this.testResultMessages[data.itemid] =  this.$t('ProductDiagnosis.untested');
          }
          this.testResultMessages[`${data.itemid}_data`] = '';
        }
      }

// 特定处理M9的逻辑
      if (data.itemid === "M9") {
        const stopTestCommand = {cmd: "stopMotorTest", param: ""};
        this.sendCommand(stopTestCommand);
      }
    },

    shouldShowTestStep(testId) {
      // 检查当前的测试步骤数组中是否有匹配的ID，并与当前的测试步骤索引相对应
      return this.activeStep === 2 && this.testSteps[this.currentTestStep]?.id === testId;
    },

    // 初始化勾选的测试项的结果
    initializeTestResultsForSelectedItems() {
      this.testResultMessages = {}; // 重置测试结果对象

      this.testItems.forEach(category => {
        category.tests.forEach(test => {
          if (category.checkedTests.includes(test.label)) {
            this.testResultMessages[test.itemid] = ''; // 直接赋值
          }
        });
      });
    },

    // 添加一个方法来等待显示信息被处理
    waitForDisplayInfo() {
      return new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (this.isDisplayInfoReceived) {
            clearInterval(intervalId);
            resolve();
          }
        }, 100); // 每100毫秒检查一次
      });
    },

    async nextStep() {
      if (this.activeStep === 1) {
        this.generateTestSteps();

        // 检查是否至少选择了一个类别项
        const anyCategorySelected = this.testItems.some(item => item.checked);

        if (!anyCategorySelected) {
          // 如果没有选择任何类别项，则提示用户
          this.$message({
            message: this.$t('ProductDiagnosis.selectCategoryWarning'),
            type: 'warning',
            duration: 3000 // 持续时间3秒
          });
          return;
        }

        // 检查是否至少选择了一个测试项
        const anyTestSelected = this.testItems.some(item => item.checkedTests.length > 0);
        if (!anyTestSelected) {
          // 如果没有选择任何测试项，则提示用户
          this.$message({
            message: this.$t('ProductDiagnosis.selectTestWarning'),
            type: 'warning',
            duration: 3000 // 持续时间3秒
          });

          return;
        }

        // 初始化选中测试项的结果
        this.initializeTestResultsForSelectedItems();

        // 检查是否有仪表盘测试项被勾选
        const dashboardTestChecked = this.testItems.some(item =>
            item.category === this.$t('ProductDiagnosis.display') && item.checkedTests.length > 0
        );

        // 如果有仪表盘检测项被勾选，发送获取显示信息配置的命令
        if (dashboardTestChecked) {
          this.sendCommand({"cmd": "getDisplayInfo_Config", "param": ""});
          //await this.getDisplayInfoConfigPromise(); // 等待获取显示信息配置的响应
          // 等待显示信息被处理
          await this.waitForDisplayInfo();
        }

        await this.updateStepCommands();

        if (this.testSteps && this.testSteps.length > 0) {
          // 有测试步骤时，准备执行第一个测试步骤
          this.currentTestStep = 0;
          this.activeStep += 1;
          this.startCountdown(); // 重置并开始倒计时

          EventBus.emit('test-status-change', true); // 发送测试正在进行的状态
          this.isTesting = true;  // 在有确认的测试步骤后设置

          // 发送第一个测试步骤的命令
          const currentTest = this.testSteps[this.currentTestStep];
          const command = this.stepCommands[currentTest.name];

          if (!currentTest || !command || !command.itemid) {
            console.error('Current test step or command is not properly initialized', currentTest);
            return;
          }

          // 假设每个测试步骤的ID或名称都已经定义好
          const currentTestId = this.testSteps[this.currentTestStep]?.id;

          // 根据测试步骤ID调整倒计时时间
          if (currentTestId === 'appCommunicationTest') {
            this.totalTime = this.remainingTime = 180; // APP通讯检测为100秒
          } else if (currentTestId === 'buttonTest') {
            this.totalTime = this.remainingTime = 25; // 按键检测为10秒
            this.startCountdown();
            this.handleButtonClick(0); // 开始第一个按键的测试
            if (this.displayedButtons && this.displayedButtons.length > 0) {
              this.displayedButtons[0].status = 'detecting'; // 设置第一个按键的状态为“检测中”
            }
          } else {
            this.totalTime = this.remainingTime = 30; // 其他测试为默认的30秒
          }

          if (currentTest.name === this.$t('ProductDiagnosis.motorDriveTest')) {
            this.showBatteryCheckModal = true; // 显示电池检查模态框
            clearInterval(this.countdownInterval); // 停止倒计时
            // 这里可能需要return或者其他逻辑来阻止发送命令
          } else {
            // 发送第一个测试步骤的命令
            if (command) {
              this.sendCommand(command);
            }
          }

        } else {
          // 没有测试步骤时直接进入下一步
          this.activeStep += 1;
        }
      }
      // 判断是否为最后一步
      this.isLastStep = this.activeStep - 1 === this.testSteps.length;
    },

    proceedToNextTestStep() {
      if (this.currentTestStep >= this.testSteps.length) {
        console.error('currentTestStep index out of bounds');
        return;
      }
      // 直接从当前测试步骤获取 itemid
      const currentItemId = this.testSteps[this.currentTestStep]?.id;
      const currentTestCommand = this.testSteps[this.currentTestStep]?.command;

      if (!currentTestCommand) {
        console.error('command is undefined for the current test step');
        return;
      }

      const currentTestItemId = this.testSteps[this.currentTestStep]?.command.itemid;

      if (currentItemId === "motorDriveTest") {
        const stopTestCommand = {cmd: "stopMotorTest", param: ""};
        this.sendCommand(stopTestCommand);
      }

      const currentStepName = this.testSteps[this.currentTestStep]?.name;
// 获取下一个测试步骤的名称，用于后续逻辑判断
      const nextStepName = this.testSteps[this.currentTestStep + 1]?.name;

      // 检查是否为大灯检测，并且是否选择了结果
      if (currentStepName === this.$t('ProductDiagnosis.headlightTest') && !this.lightResult) {
        this.$message({
          message: this.$t('ProductDiagnosis.selectHeadlightResult'),
          type: 'warning',
          duration: 3000 // 持续时间3秒
        });
        return; // 退出函数，不进行下一步
      }

      if (currentStepName !== 'buttonTest') {
        // 检查当前步骤是否完成，这里的逻辑可能需要根据你的应用逻辑调整
        const isCurrentStepComplete = this.checkIfCurrentStepComplete(currentStepName);

        if (!isCurrentStepComplete) {
          if (currentStepName !== this.$t('ProductDiagnosis.headlightTest')) {
            // 如果当前步骤未完成，发送取消测试命令
            const cancelCommand = {"cmd": "DeviceTestCancel", "param": currentTestItemId ? currentTestItemId : ""};
            this.sendCommand(cancelCommand);
            this.$message({
              message: this.$t('ProductDiagnosis.skippingTestItem'),
              type: 'warning',
              duration: 3000 // 持续时间3秒
            });
          }

        }
      }

      if (currentStepName === this.$t('ProductDiagnosis.headlightTest')) {
        this.sendCommand({cmd: "closeLight", param: ""});

        // 无论是否收到关闭大灯的响应，都在一定时间后继续执行下一步
        setTimeout(() => {
          this.continueToNextTestStep(nextStepName);
        }, 500); // 3秒后执行，此时间可以根据实际情况调整

      } else {
        this.continueToNextTestStep(nextStepName);
      }

    },

    // 新增一个方法来处理继续下一步的逻辑
    continueToNextTestStep(nextStepName) {
      // 此处currentIndex参数未使用，而是直接操作this.currentTestStep
      if (this.currentTestStep < this.testSteps.length - 1) {

        // 移动到下一个测试步骤
        this.currentTestStep += 1;

        // 在准备跳转到下一测试步骤之前，重置并开始倒计时
        this.startCountdown();

        if (this.currentTestStep === this.testSteps.length - 1) {
          this.isLastStep = true;
        }

        if (nextStepName === this.$t('ProductDiagnosis.motorDriveTest')) {
          this.showBatteryCheckModal = true; // 如果是，则显示电池检查模态框
          clearInterval(this.countdownInterval); // 停止倒计时
        } else {
          const command = this.stepCommands[nextStepName];
          if (command) {
            this.sendCommand(command);
          }
        }
      } else {
        // 已经是最后一个测试步骤，适当处理，比如显示完成按钮等
        this.isLastStep = true; // 设置为最后一步
        // 完成所有测试步骤，递增activeStep以退出测试

        this.activeStep += 1;
      }
    },

    sendCommand(command) {
      try {
        this.getWs.send(JSON.stringify(command));
      } catch (error) {
        console.error(this.$t('ProductDiagnosis.commandSendError'), error);
      }
    },

    generateTestSteps() {

      this.testSteps = []; // 清空当前的测试步骤数组
      // 遍历所有测试项，根据选中状态添加到testSteps
      this.testItems.forEach(categoryItem => {
        categoryItem.tests.forEach(test => {
          if (categoryItem.checked && Array.isArray(categoryItem.checkedTests) && categoryItem.checkedTests.includes(test.label)) {
            this.testSteps.push({
              id: test.id, // 使用唯一标识符
              name: test.label,
              command: this.stepCommands[test.label] // 假设stepCommands已经包含了对应测试的命令信息
            });
          }
        });
      });
    },

    getItemIdFromStepName(stepName) {
      // 这里假设每个命令对象都有一个itemid字段
      return this.stepCommands[stepName]?.itemid || null;
    },

    // 根据你的应用逻辑实现这个方法，判断当前步骤是否完成
    checkIfCurrentStepComplete(stepName) {
      // 从步骤名称获取itemid
      const itemid = this.getItemIdFromStepName(stepName);
      // 检查testResultMessages对象中对应itemid的结果是否存在且不是空字符串
      return (this.testResultMessages[itemid] !== undefined && this.testResultMessages[itemid] !== '') || this.remainingTime <= 0;
    },

    firstNextStep() {
      if (this.activeStep === 0) {
        // 当从第一步转到第二步时，根据选择的外设更新测试项
        this.filterTestItemsBasedOnSelection();
      }

      if (this.activeStep < this.totalTestStep) {
        this.activeStep++;
      }
    },

    filterTestItemsBasedOnSelection() {
      const valueToLabelMap = this.getValueToLabelMap();
      this.testItems.forEach(categoryItem => {
        if (!Array.isArray(this.checkedList) || !Array.isArray(this.alwaysShownTests)) {
          console.error('checkedList or alwaysShownTests is not an array', this.checkedList);
          return;
        }

        let newCheckedTests = categoryItem.tests.filter(test =>
            (typeof valueToLabelMap[test.value] === 'string' && this.checkedList.includes(valueToLabelMap[test.value])) ||
            this.alwaysShownTests.includes(test.value)
        ).map(test => test.label);

        if (categoryItem.category === this.$t('ProductDiagnosis.motor') && this.motorConfigData && this.motorConfigData.MotorTemperatureProtectionEnable === '1') {
          const motorTempTestValue = categoryItem.tests.find(test => test.id === 'motorTempTest')?.label;
          if (motorTempTestValue && !newCheckedTests.includes(motorTempTestValue)) {
            newCheckedTests.push(motorTempTestValue);
          }
        }

        // 如果当前类别默认选中（电机和仪表），则保留默认选中状态
        if (categoryItem.category === this.$t('ProductDiagnosis.motor') && this.isMotorConnected) {
          //categoryItem.checkedTests = categoryItem.tests.map(test => test.label);
          categoryItem.checkedTests = newCheckedTests;
          categoryItem.checked = true;
        } else if (categoryItem.category === this.$t('ProductDiagnosis.display') && this.isDisplayConnected) {
          categoryItem.checkedTests = categoryItem.tests.map(test => test.label);
          categoryItem.checked = true;
        } else {
          categoryItem.checkedTests = newCheckedTests;
          categoryItem.checked = newCheckedTests.length > 0;
        }
      });

      // 更新测试步骤
      // this.generateTestSteps();
    },
    getFilteredTests(row) {
      const valueToLabelMap = this.getValueToLabelMap();
      let filteredTests = [];

      if (!Array.isArray(this.checkedList) || !Array.isArray(this.alwaysShownTests)) {
        console.error('checkedList or alwaysShownTests is not an array');
        return []; // 提早返回空数组以避免错误
      }

      if (row.category === this.$t('ProductDiagnosis.motor')) {
        filteredTests = row.tests.filter(test => {
          const isInCheckedList = this.checkedList.includes(valueToLabelMap[test.value]);
          const isInAlwaysShownTests = this.alwaysShownTests.includes(test.value);
          return isInCheckedList || isInAlwaysShownTests;
        });

        if (this.motorConfigData && this.motorConfigData.MotorTemperatureProtectionEnable === '1') {
          const motorTempTestExists = filteredTests.some(test => test.id === 'motorTempTest');
          if (!motorTempTestExists) {
            const motorTempTest = row.tests.find(test => test.id === 'motorTempTest');
            if (motorTempTest) {
              filteredTests.push(motorTempTest);
            }
          }
        }

      } else if (row.category === this.$t('ProductDiagnosis.display')) {
        filteredTests = row.tests;
      }

      return filteredTests;
    },

    previousStep() {
      if (this.activeStep > 0) {
        this.activeStep--;
      }
    },
    shouldDisplayRow(row) {
      if (row.category === this.$t('ProductDiagnosis.motor')) {
        return this.isMotorConnected;
      } else if (row.category === this.$t('ProductDiagnosis.display')) {
        return this.isDisplayConnected;
      }
      return true;
    },
    formatTime() {
      // 因为剩余时间与百分比成正比，我们可以直接返回剩余时间
      return `${this.remainingTime}s ${this.$t('ProductDiagnosis.remainingTime')}`;
    },

    startCountdown() {
      // 假设每个测试步骤的ID或名称都已经定义好
      const currentTestStep = this.testSteps[this.currentTestStep];
      if (!currentTestStep) {
        console.error('currentTestStep is undefined');
        return;
      }

      const currentTestId = currentTestStep.id;
      const currentTestItemId = currentTestStep.command?.itemid;
      if (currentTestId !== 'headlightTest') {
        if (!currentTestId || !currentTestItemId) {
          console.error('Missing currentTestId or currentTestItemId', currentTestStep);
          return;
        }
      }

      // 根据测试步骤ID调整倒计时时间
      if (currentTestId === 'appCommunicationTest') {
        this.totalTime = this.remainingTime = 180; // APP通讯检测为180秒
      } else if (currentTestId === 'buttonTest') {
        this.totalTime = this.remainingTime = 25; // 按键检测为10秒
        // 在进入按键测试阶段时设置 isInButtonTest 为 true
        this.isInButtonTest = true;
      } else {
        this.totalTime = this.remainingTime = 30; // 其他测试为默认的30秒
      }

      // 如果已存在倒计时，先清除现有的倒计时。这确保不会有多个倒计时同时运行。
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }

      // 设置一个新的倒计时。这里使用 setInterval 每秒钟减少剩余时间，并执行相关逻辑。
      this.countdownInterval = setInterval(() => {

        if (this.remainingTime > 0) {
          // 如果还有剩余时间，减少1秒。
          this.remainingTime -= 1;
        } else {
          // 如果时间用完，执行以下逻辑。
          clearInterval(this.countdownInterval); // 停止倒计时
          if (!this.testResultReceived) {
            // 只有在没有收到结果时才发送取消命令
            const cancelCommand = {"cmd": "DeviceTestCancel", "param": currentTestItemId ? currentTestItemId : ""};
            this.sendCommand(cancelCommand);
          }
          if (this.isInButtonTest) {
            // 检查当前按键的状态是否为'detecting'
            if (this.displayedButtons[this.currentButtonIndex]?.status === 'detecting') {
              // 假设如果检测中状态下时间耗尽，则默认为未通过
              this.displayedButtons[this.currentButtonIndex].status = 'failed';
            }

            if (this.currentButtonIndex < this.displayedButtons.length - 1) {

              this.currentButtonIndex++;
              // 如果当前按键不是最后一个，移动到下一个按键，并开始检测。
              this.handleButtonClick(this.currentButtonIndex); // 自动触发下一个按键的测试
            } else {
              // 如果当前按键是最后一个
              this.isInButtonTest = false; // 标记按键测试阶段结束
              if (!this.isLastStep) {
                this.proceedToNextTestStep(); // 根据你的业务逻辑，这里可以是进入下一测试步骤或者结束整个测试流程
              } else {
                clearInterval(this.countdownInterval);
              }
            }
          } else {
            if (!this.isLastStep) {
              // 如果还没有到达测试流程的最后一步，继续下一步
              this.proceedToNextTestStep();
            }
          }
        }
      }, 1000); // setInterval 的时间间隔设置为1000毫秒，即每秒执行一次。
    },

    onCategoryCheckedChange(row) {
      if (row.checked) {
        row.checkedTests = row.tests.map(test => test.label);
      } else {
        row.checkedTests = [];
      }

      if (row.category === this.$t('ProductDiagnosis.display')) {
        this.updateDisplayTestsSelection(row.checked); // 更新仪表盘测试项的选中状态
      }
    },
    toggleCategory(row) {
      const allSelected = row.checkedTests.length === row.tests.length;
      if (allSelected) {
        row.checked = false;
        row.checkedTests = [];
      } else {
        row.checked = true;
        row.checkedTests = row.tests.map(test => test.label);
      }
      this.onCategoryCheckedChange(row); // 调用 onCategoryCheckedChange 来处理选中状态变化

    },
    updateDisplayTestsSelection(isChecked) {
      // 查找仪表盘的测试项
      const displayTestItem = this.testItems.find(item => item.category === this.$t('ProductDiagnosis.display'));
      if (displayTestItem) {
        if (isChecked) {
          // 将所有仪表盘测试项标记为选中
          displayTestItem.checkedTests = displayTestItem.tests.map(test => test.label);
        } else {
          // 清空所有仪表盘测试项的选中状态
          displayTestItem.checkedTests = [];
        }
      }
    },

    deviceName(deviceCode) {
      const deviceName = {
        '1': this.$t('ProductDiagnosis.brakeSensor'),
        '2': this.$t('ProductDiagnosis.wheelSensor'),
        '3': this.$t('ProductDiagnosis.pedalSensor'),
        '4': this.$t('ProductDiagnosis.handlebarSensor'),
        '5': this.$t('ProductDiagnosis.torqueSensor'),
      };
      return deviceName[deviceCode] || this.$t('ProductDiagnosis.unknownDevice');
    },

    async startTest() {
      this.testCompleted = false;
      this.testStarted = true;
      this.activeStep = 0; // 确保从第一步开始
      // 在开始测试时重置测试步骤
      this.currentTestStep = 0;
      this.checkedList = this.persistSelections(); // 保持选项一致
      this.remainingTime = 30;
      this.testResultMessages = {};

      this.lightResult = null;

      // 调用初始化测试项的方法，基于电机和仪表的连接状态
      this.initializeTestItemsBasedOnConnection();

      if (this.motorConfigData && this.motorConfigData.externaldevices) {
        // 使用 deviceName 函数将设备代码转换为设备名称
        this.checkedList = this.motorConfigData.externaldevices.map(code => this.deviceName(code)).filter(name => name !== this.$t('ProductDiagnosis.unknownDevice'));
      } else {
        this.checkedList = [];
      }

      // 重置按键测试的状态
      this.resetButtonTestsStatus();

      this.$emit('start-test');
      EventBus.emit('test-started', true);

      try {
        // 记录操作，传递一个包含 action 和 result 的 data 对象
        await recordOperation({ action: 'dynamic-test' });
      } catch(error) {
        console.error("Failed to record operation:", error);
      }
    },
    persistSelections() {
      // 保存当前选中的选项以便在切换语言时保持一致
      return this.checkedList;
    },
    initializeTestItemsBasedOnConnection() {
      // 创建一个映射表，将 availableDevices 中的名称映射到 tests 的标签
      /*const deviceLabelMap = {
        [this.$t('ProductDiagnosis.torqueSensor')]: this.$t('ProductDiagnosis.torqueSensorTest'),
      };*/
      const deviceLabelMap = {
        [this.$t('ProductDiagnosis.torqueSensor')]: 'torqueSensorTest',
        [this.$t('ProductDiagnosis.cadenceSensor')]: 'cadenceSensorTest',
        [this.$t('ProductDiagnosis.brakeSensor')]: 'brakeSensorTest',
        [this.$t('ProductDiagnosis.gearSensor')]: 'gearSensorTest',
        [this.$t('ProductDiagnosis.throttle')]: 'throttleSignalTest',
      };
      // 将 availableDevices 转换为对应 tests 标签的名称
      const mappedAvailableDevices = this.availableDevices.map(device => deviceLabelMap[device] || device);

      let newTestItems = [];

      if (this.isMotorConnected) {
        newTestItems.push({
          category: this.$t('ProductDiagnosis.motor'),
          checked: true, // 默认勾选电机类别
          tests: [
            {label: this.$t('ProductDiagnosis.speedSensorTest'), value: 6, id: 'speedSensorTest', itemid: 'M2'},
            {label: this.$t('ProductDiagnosis.cadenceSensorTest'), value: 3, id: 'cadenceSensorTest', itemid: 'M3'},
            {label: this.$t('ProductDiagnosis.torqueSensorTest'), value: 4, id: 'torqueSensorTest', itemid: 'M6'},
            {label: this.$t('ProductDiagnosis.headlightTest'), value: 7, id: 'headlightTest', itemid: 'M1'},
            {label: this.$t('ProductDiagnosis.brakeSensorTest'), value: 2, id: 'brakeSensorTest', itemid: 'M5'},
            {label: this.$t('ProductDiagnosis.gearSensorTest'), value: 1, id: 'gearSensorTest', itemid: 'M4'},
            {label: this.$t('ProductDiagnosis.throttleSignalTest'), value: 5, id: 'throttleSignalTest', itemid: 'M7'},
            {label: this.$t('ProductDiagnosis.motorDriveTest'), value: 8, id: 'motorDriveTest', itemid: 'M9'}
          ].filter(test => {
            return ['speedSensorTest', 'headlightTest', 'motorDriveTest'].includes(test.id) ||
                mappedAvailableDevices.some(device => test.id.includes(device));
          }).map(test => ({
            ...test,
            checked: true // 默认勾选所有电机测试项
          }))
        });
      }

      if (this.isDisplayConnected) {
        const dashboardTests = [
          {label: this.$t('ProductDiagnosis.buttonTest'), value: 9, id: 'buttonTest', itemid: 'D1'},
          {
            label: this.$t('ProductDiagnosis.appCommunicationTest'),
            value: 10,
            id: 'appCommunicationTest',
            itemid: 'D2'
          }
        ].map(test => ({
          ...test,
          checked: true // 默认勾选所有仪表盘测试项
        }));

        const checkedDashboardTests = dashboardTests.map(test => test.label);

        newTestItems.push({
          category: this.$t('ProductDiagnosis.display'),
          checked: true, // 默认勾选仪表盘类别
          tests: dashboardTests,
          checkedTests: checkedDashboardTests // 使所有仪表盘测试项默认选中
        });

        this.checkedList.push(...checkedDashboardTests);
      }

      // 电机部分的选项根据motorConfigData?.externaldevices更新
      if (this.motorConfigData && this.motorConfigData.externaldevices) {
        // 注意：这里假设externaldevices数组中存储的是字符串形式的数字
        this.checkedList = this.motorConfigData.externaldevices.map(Number) // 将字符串转换为数字
            .map(value => {
              const testItem = newTestItems[0].tests.find(test => test.value === value);
              return testItem ? testItem.label : null;
            })
            .filter(label => label !== null);
      }

      // 动态添加电机温度检测项，如果有温度传感器
      if (this.motorConfigData && this.motorConfigData.MotorTemperatureProtectionEnable === '1') {
        const motorTempTest = {
          label: this.$t('ProductDiagnosis.motorTempTest'),
          value: 9,
          id: 'motorTempTest',
          itemid: 'M8'
        };
        newTestItems[0].tests.push(motorTempTest);
      }

      this.testItems = newTestItems; // 更新测试项目数组

      this.updateCheckedList(); // 更新 checkedList 以反映这些默认勾选项
    },

    updateCheckedList() {
      // 收集所有被勾选的测试项标签
      this.checkedList = this.testItems
          .flatMap(category =>
              category.checked ? category.tests.map(test => test.label) : []
          );
    },

    resetButtonTestsStatus() {
      // 重置每个按键的状态为'waiting'
      this.buttonTests.forEach(button => {
        button.status = 'waiting';
      });
      // 默认检测第一个按键并显示“检测中”
      this.currentButtonIndex = 0;
      if (this.displayedButtons.length > 0) {
        this.displayedButtons[0].status = 'detecting';
      }
    },

    cancelTest() {
      // 直接从当前测试步骤获取 itemid
      const currentItemId = this.testSteps[this.currentTestStep]?.id;
      if (currentItemId === "motorDriveTest") {
        const stopTestCommand = {cmd: "stopMotorTest", param: ""};
        this.sendCommand(stopTestCommand);
      }
      // 如果需要发送关灯指令（例如在大灯测试步骤中），可以在这里加入条件检查
      if (this.testSteps[this.currentTestStep]?.id === 'headlightTest') {
        this.sendCommand({
          cmd: "closeLight",
          param: ""
        });
      }
      if (this.activeStep !== 0) {
        this.sendCommand({
          cmd: "DeviceTestCancel",
          param: this.testSteps[this.currentTestStep]?.command.itemid ? this.testSteps[this.currentTestStep]?.command.itemid : "",
        });
      }
      // 重置测试状态和UI
      this.resetTestState();
      // 测试取消
      this.$emit('cancel-test');
      EventBus.emit('test-started', false);
    },

    resetTestState() {
      this.testStarted = false;
      EventBus.emit('test-status-change', false); // 发送测试结束的状态
      this.isTesting = false; // 确保同时重置测试状态
      this.activeStep = 0;
      this.currentTestStep = 0;
      this.remainingTime = 30;
      clearInterval(this.countdownInterval); // 停止倒计时

      // 重置所有用于UI显示和逻辑控制的变量
      this.testResultMessages = {};
      this.testSteps = [];
      // 重置 checkedList 基于当前的设备配置
      if (this.motorConfigData && this.motorConfigData.externaldevices) {
        this.checkedList = this.motorConfigData.externaldevices.map(code => this.deviceName(code)).filter(name => name !== this.$t('ProductDiagnosis.unknownDevice'));
      } else {
        // 如果没有外设或外设信息不可用，可以选择清空列表或设置为默认值
        this.checkedList = [];
      }
    },

    handleButtonClick(index) {
      const button = this.displayedButtons[index]; // 获取当前按钮对象

      if (!button) {
        console.error('Button not found at index:', index);
        return;
      }
      // 遍历所有按钮，根据不同的状态进行相应处理
      this.displayedButtons.forEach((btn, idx) => {
        if (btn.status === 'detecting' && idx !== index) {
          btn.status = 'failed'; // 将当前检测中的按钮状态设为不通过
        }
      });

      // 将当前点击的按钮状态设为检测中
      button.status = 'detecting';
      this.currentButtonIndex = index;
      //if (button.status === 'waiting') {
      // this.currentButtonIndex = index;
      //button.status = 'detecting';  // 直接操作button对象

      // 开始倒计时
      this.startCountdown();

      const command = {
        "cmd": "DeviceTest",
        "itemid": "D1", // 使用对应按钮的itemid
        "itemname": "",
        "p1": button.p1, // 动态获取当前按钮的 p1 值
        "p2": "",
        "maxTestCount": "10"
      };
      this.sendCommand(command); // 发送指令
      // 设置 isInButtonTest 为 true，表示进入按键测试阶段
      this.isInButtonTest = true;
      //}
    },


    // 参数可以根据你的实际情况进行调整
    shouldShowSpinner(testResult, remainingTime) {
      return testResult === '' && remainingTime > 0;
    },
    testResultClass(testResult) {
      return testResult === this.$t('ProductDiagnosis.passed') ? 'passed' : 'failed';
    }

  }
}
</script>

<style scoped>
.placeholder-text {
  width: 300px; /* 与 img-size 中的 max-width 匹配 */
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #000;
  text-align: center;
}

.spinner-container {
  position: relative;
  width: 18px;
  height: 18px;
}

.dot1, .dot2 {
  width: 10px;
  height: 10px;
  background-color: rgb(0, 154, 68); /* 绿色 */
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 50%;
  transform-origin: 0 50%;
  animation: spin 1s linear infinite;
}

.dot2 {
  animation-delay: -0.5s; /* 延迟半秒开始动画，使两点交替旋转 */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dialog-separator-other {
  border-top: 0.5px solid rgba(221, 221, 221, 0.1);
  width: 100%; /* 设置宽度为100%，确保与父容器内宽相匹配 */
}


.button-footer {
  margin-bottom: 10px;
}

.step-label {
  margin-left: 20px;
}

.step-progress {
  margin-right: 10px;
  margin-left: 10px;
  width: 300px;
  min-width: 150px; /* 或者其他足够显示进度条的最小宽度 */
}

.progress-container {
  display: flex;
  align-items: center;
}

.custom-button {
  background-color: rgb(245, 245, 245); /* 设置背景色为红色 */
  border-color: rgb(217, 217, 217); /* 设置边框颜色为红色，如果需要 */
  color: rgb(184, 184, 184); /* 设置边框颜色为红色，如果需要 */
}

.custom-button:hover {
  background-color: rgb(245, 245, 245); /* 鼠标悬停时保持相同的背景色 */
  border-color: rgb(217, 217, 217); /* 鼠标悬停时保持相同的边框颜色 */
  color: rgb(184, 184, 184); /* 鼠标悬停时保持相同的文字颜色 */
}

/* 当按钮可点击时应用的样式 */
.button-active {
  background-color: rgb(0, 154, 68); /* 设置背景为白色 */
  color: white; /* 可以调整文字颜色以保持可读性 */
  border-color: #dcdfe6; /* 可选：设置边框颜色 */
}

/* 悬停在 .button-active 上时的样式 */
.button-active:hover {
  color: white; /* 悬停时文字颜色为绿色 */
  border-color: rgb(0, 154, 68); /* 悬停时边框颜色为绿色 */
  background-color: rgb(0, 154, 68); /* 悬停时背景色为白色 */
}

.restart-test {
  text-align: left;
  margin: 0 0 10px 10px;
}

.anjian-detecting {
  color: blue; /* 蓝色表示检测中 */
  font-size: 14px;
}

.anjian-passed {
  color: rgb(0, 154, 68); /* 绿色表示通过 */
  font-weight: bold;
  font-size: 14px;
}

.anjian-failed {
  color: red; /* 红色表示不通过 */
  font-size: 14px;
}

.anjian-waiting {
  color: #A5A2A2; /* 暗色表示待检测 */
  font-size: 14px;
}

.button-icon {
  width: 30px;
  height: 30px;
  object-fit: contain; /* 保持图片比例 */
}

.button-item {
  margin-right: 5px;
}

.buttons-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* 如果按钮太多，需要换行排列 */
}

.top-distance {
  margin-top: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg); /* 开始时旋转角度 */
  }
  100% {
    transform: rotate(360deg); /* 结束时旋转角度 */
  }
}

.right-distance {
  margin-right: 40px;
}

.test-num {
  color: #000000D9;
  font-size: 30px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin: 0 16px 0 0;
}

.test-feedback-container {
  display: flex;
  flex-direction: column;
}

.passed {
  color: #009A44;
  font-size: 30px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.failed {
  color: red;
  font-size: 30px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.num-result {
  display: flex;
  justify-content: center; /* 确保子元素之间的间距均匀分布 */

}

.align-num-res {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.test-content {
  color: #A5A2A2;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin: 0 0 16px;
}

.test-title {
  color: #A5A2A2;
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.main-title {
  color: #000000D9;
  font-size: 26px;
  font-family: "Pingfang sC", serif;
  margin: 0 0 20px;
}

.progress-bar-container {
  width: 80%;
  margin: 0 auto;
}

.step-same-height {
  border: 1px solid rgba(196, 196, 196, 0.325); /* 加上透明色的边框 */
  border-radius: 8px; /* 可选：为边框添加圆角效果 */
}

/* 调整动态测试内容区域的背景颜色和内边距 */
.dynamic-test-content {
  background-color: white; /* 背景颜色设置为白色 */
  padding: 0; /* 调整内边距大小，根据需要自行调整 */
}

/* 设备盒子样式，包括边框和内边距 */
.device-connection {
  border: 2px solid #DDD; /* 添加2像素的黑色边框，可以根据需要调整颜色和边框宽度 */
  min-height: 400px; /* 最小高度 */
  height: auto; /* 高度自适应 */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  overflow-y: auto; /* 超出时显示滚动条 */
}

.checkbox-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.test-step-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 300px;
  padding: 20px;
}

.img-size {
  width: 300px; /* 固定宽度为300px */
  height: 230px; /* 固定高度为230px */
  object-fit: contain; /* 保持图片比例并适应容器 */
}

.bg-progress {
  display: flex;
  align-items: center;
  background-color: rgb(245, 245, 245);
  height: 50px;
}

.progress-width {
  width: 30%;
}

</style>